import React, {
  CSSProperties,
  ComponentType,
  HTMLAttributes,
  ReactNode,
} from 'react';
import '../assets/svg/arrow-down-12x12.svg';

interface WithArrowDownProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode | string | undefined | false;
  style?: CSSProperties;
  isRotated: boolean;
}

export function withArrowDown<T extends WithArrowDownProps>(
  Component: ComponentType<T>,
) {
  // eslint-disable-next-line react/display-name
  return (props: WithArrowDownProps & T) => {
    const { children, style, isRotated } = props;

    const divStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '6px',
    };

    const divIconStyle = {
      transition: 'transform 200ms',
    };

    return (
      <Component {...props}>
        <div style={{ ...divStyle, ...style }}>
          {children}
          <div
            className="icon"
            style={
              isRotated
                ? { ...divIconStyle, transform: 'rotate(180deg)' }
                : { ...divIconStyle }
            }
          >
            <svg>
              <use xlinkHref="#arrow-down-12x12"></use>
            </svg>
          </div>
        </div>
      </Component>
    );
  };
}
