import React, { useEffect, useState } from 'react';
import { SquareButton } from '../../shared/SquareButton/SquareButton';
import { Transition } from 'react-transition-group';
import './BackToTopButton.scss';

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

/**
 * Кнопка прокрутки к верху экрана
 */
export const BackToTopButton = () => {
  const scrollUp = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    const showButton = () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    };

    addEventListener('scroll', showButton);

    return () => removeEventListener('scroll', showButton);
  }, [backToTopButton]);

  return (
    <Transition in={backToTopButton} timeout={duration}>
      {(state) => (
        // @ts-ignore
        <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
          <SquareButton
            theme="transparent"
            arrow="up"
            onclick={scrollUp}
            mixin="up-button"
          />
        </div>
      )}
    </Transition>
  );
};
