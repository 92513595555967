import React from 'react';
import { Link } from 'react-router-dom';
import { withBullet } from '../../../hocs/withBullet';
import { useRouteData } from '../../../hooks/useRouteData';
import './Service.scss';

const ElementWithBullet = withBullet(({ children }) => <>{children}</>);

export const Service = () => {
  const { Crumbs, title } = useRouteData();

  return (
    <div className="container">
      <Crumbs />
      <h2>{title}</h2>
      <article className="article">
        <p>
          Компания БухЭталонЪ предлагает все возможные бухгалтерские услуги для
          ООО, включая налоговый учёт. Мы находимся в Санкт-Петербурге, но не
          ограничены территориально — наши клиенты работают в разных регионах,
          среди них есть и зарубежные компании.
        </p>
        <p>Наиболее популярные услуги у наших клиентов:</p>
        <ul>
          <Link to={'/service/buhgalterskie-uslugi-dlya-ip'}>
            <ElementWithBullet>Бухгалтерские услуги для ИП</ElementWithBullet>
          </Link>
          <Link to={'/service/zapolnenie-deklaracii-3-ndfl'}>
            <ElementWithBullet>Заполнение декларации 3-НДФЛ</ElementWithBullet>
          </Link>
        </ul>
      </article>
    </div>
  );
};
