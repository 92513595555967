import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { routesList as list } from '../components/App/routeConfig';
import { Breadcrumbs } from '../components/shared/Breadcrumbs/Breadcrumbs';

type TParamsIdData = {
  paramsId: string;
  title: string;
  description: string;
}

const initialInfo = {
  path: '', 
  title: 'Страница 404',
  description: 'Бухгалтерские услуги для ООО и ИП в Санкт-Петербурге и удаленно по всей России. Опытные бухгалтеры, выгодные цены, онлайн-калькулятор стоимости. Звоните +7 (812) 642-25-74"',
}

// очищает строку от слэшей в конце, кроме случая корневого маршрута '/'
const clearTrailingSlash = (string: string) => {
  if (string === '/') return string;
  return  string.endsWith('/') ? string.replace(/\/+$/, '') : string;
}

/**
 * Хук предоставляет адрес текущего url с доп параметрами
 */
export const useRouteData = (paramsIdData?: TParamsIdData) => {
  const location = useLocation();
  const [path] = useState(clearTrailingSlash(location.pathname));
  const [routes, setRoutes] = useState(list); // список роутов с доп параметрами
  const [info, setInfo] = useState(initialInfo);

  const Crumbs = () => <Breadcrumbs pathname={info.path} routesList={routes} />;

  // если есть роут с параметром, записываем его в список роутов
  useEffect(() => {
    if (paramsIdData) {
      setRoutes([...routes, { path, title: paramsIdData.title }]);
    }
  }, [paramsIdData?.paramsId]);

  // находим текущий роут в списке роутов и инфу о нём записываем в переменную info
  useEffect(() => {
    const route = routes.find((item) => item.path === path);

    if (route && route !== null) {
      setInfo({ path: route.path, title: route.title, description: route.description });
    } else {
      setInfo(initialInfo);
    }
  }, [path, routes]);

  // устанавливаем title и description документа
  useEffect(() => {
    document.title = info.title + ' - Компания БухЭталонЪ';
    document.querySelector('META[name="description"]')?.setAttribute('content', info.description);
  }, [info]);

  const updateRoutes = (paramsIdData: TParamsIdData) => {
    if (paramsIdData) {
      const temp = routes.filter((item) => item.path !== path);
      setRoutes([...temp, { path, title: paramsIdData.title, description: paramsIdData.description }]);
    }
  }
// console.log(routes)
  return { info, routes, Crumbs, title: info.title, updateRoutes };
};
