import React from 'react';
import { Button } from '../../shared/Button/Button';
import { useCallbackForm } from '../../shared/Callback/CallbackFormContext';
import { useYandexMetrika } from '../../../hooks/useYandexMetrika';
import './Calculator.scss';

type TariffSetProps = {
  data: Record<string, string>[];
};

const numberWithSpaces = (num: string) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const TariffSet = ({ data }: TariffSetProps) => {
  const { setCallbackFormOpen } = useCallbackForm();
  const { reachGoal } = useYandexMetrika();

  return (
    <div className="tariffs">
      {data.map(({ title, price }) => (
        <div key={title} className="tariffs__tariff">
          <div className="container">
            <div>{`Тариф "${title.toUpperCase()}"`}</div>
            <div>
              Ориентировочная стоимость:
              <div>{`${numberWithSpaces(price)} ₽`}</div>
            </div>
            <div>
              <Button theme="beige" onClick={() => {setCallbackFormOpen(),reachGoal('entrustService')}}>
                Доверить обслуживание
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
