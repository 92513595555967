import React from 'react';
import { NavLinks } from '../../public/PublicLayout/NavLinks/NavLinks';
import { SocialLinks } from '../../public/PublicLayout/SocialLinks/SocialLinks';
import { CSSTransition } from 'react-transition-group';
import './MobileMenu.scss';
import { ModalWrapper } from '../ModalWrapper/ModalWrapper';
import { Header } from '../../public/PublicLayout/Header/Header';

type MobileMenuProps = {
  isMobileMenuOpen: boolean;
  onClose: () => void;
};

/**
 * Мобильное меню
 */
export const MobileMenu = ({ isMobileMenuOpen, onClose }: MobileMenuProps) => {
  return (
    <CSSTransition
      in={isMobileMenuOpen}
      timeout={300}
      mountOnEnter
      unmountOnExit
      classNames="modal"
    >
      <ModalWrapper
        onClose={onClose}
        wrapperChildrenStyles={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          margin: 0,
        }}
      >
        <Header
          setMobileMenuClose={onClose}
          isMobileMenuOpen={isMobileMenuOpen}
          area={'mobile-menu'}
        />
        <div className="mobile-menu" onClick={onClose}>
          <NavLinks area={'mobile-menu'} onClose={onClose} />
          <SocialLinks />
        </div>
      </ModalWrapper>
    </CSSTransition>
  );
};
