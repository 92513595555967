import React, {
  CSSProperties,
  ComponentType,
  HTMLAttributes,
  ReactElement,
} from 'react';

interface WithSpinnerProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactElement | string;
  style?: CSSProperties;
}

export function withSpinner<T extends WithSpinnerProps>(
  Component: ComponentType<T>,
) {
  // eslint-disable-next-line react/display-name
  return (props: WithSpinnerProps & T) => {
    const { children, style } = props;

    const divStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '6px',
    };

    return (
      <Component {...props}>
        <div style={{ ...divStyle, ...style }}>
          <div className="icon spinner">
            <svg
              version="1.1"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m16.5 10a6.5004 6.5004 0 0 1-4.0128 6.0056 6.5004 6.5004 0 0 1-7.084-1.4091 6.5004 6.5004 0 0 1-1.4091-7.084 6.5004 6.5004 0 0 1 6.0056-4.0128"
                strokeLinecap="round"
                strokeWidth="2"
                fill="none"
              />
            </svg>
          </div>
          {children}
        </div>
      </Component>
    );
  };
}
