import React, { useState, useCallback } from 'react';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Question } from '../../shared/Question/Question';

type RangeProps = {
  title: string;
  popup?: string;
  min: number;
  max: number;
  cb: (value: number) => void;
} & Record<string, any>;

type Division = {
  id: number | string;
  value: number | string;
};

// TODO: вынести в отдельный файл
const getDivisions = (
  min: number,
  max: number,
  isDesktop: boolean,
): Division[] => {
  const interval = (max - min) / 16;
  const iterations = isDesktop ? 17 : 9;
  const divisions: Division[] = [];

  for (let i = 1; i <= iterations; i++) {
    if (i === 1) {
      divisions.push({ id: i, value: min });
      continue;
    }

    if (i === iterations) {
      divisions.push({ id: i, value: max });
      continue;
    }

    if (i % 2 === 0) {
      divisions.push({ id: i, value: '' });
      continue;
    }

    divisions.push({ id: i, value: Math.floor(interval * (i - 1)) });
  }

  return divisions;
};

export const Range = ({ title, min, max, popup, cb }: RangeProps) => {
  const isDesktop = useMediaQuery('(min-width: 800px)');
  const [value, setValue] = useState(min);
  const divisions = getDivisions(min, max, isDesktop);
  const handleChange = useCallback((value: number) => cb(value), [value]);

  return (
    <div className="calculator__block">
      <div className="calculator__block-title">
        {title}
        {popup && <Question popup={popup} />}
      </div>
      <div className="calculator__block-range">
        <div className="calculator__block-range-imitator">
          <div className="calculator__block-range-imitator-scale">
            <div className="calculator__block-range-imitator-divisions">
              {divisions.map(({ id, value }) => (
                <div
                  key={id}
                  data-position={value}
                  className="calculator__block-range-imitator-division"
                ></div>
              ))}
            </div>
            <div
              className="calculator__block-range-imitator-slider"
              style={{
                left: `calc(${((value - min) / (max - min)) * 100}% - 14px)`,
              }}
              data-position={value}
            ></div>
          </div>
        </div>
        <input
          type="range"
          step={1}
          value={value}
          onChange={(e) => {
            setValue(+e.target.value), handleChange(+e.target.value);
          }}
          min={min}
          max={max}
        ></input>
      </div>
    </div>
  );
};
