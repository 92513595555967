import React from 'react';
import './ErrorPage.scss';
import { Button } from '../../shared/Button/Button';

type ErrorBoundaryProps = {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorPage = ({ error, resetErrorBoundary }: ErrorBoundaryProps) => {

  console.log('Произошла ошибка: ', error)

  return (
    <div className="container">
      <article className="article">
        <div className="error-page">
          <svg className='error-page__svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
            <path
              fill="#ffcf71"
              d="M48.242 11.113c1.505 0 2.908.833 3.688 2.246l35.227 63.814c1.712 3.104-.376 6.839-3.69 6.839H13.019c-3.314 0-5.402-3.735-3.69-6.839L44.554 13.36c.78-1.413 2.183-2.246 3.688-2.246z"
              color="#000"
            />
            <path
              fill="#f3b43a"
              d="M65.564 38.059c-.264.55-.513 1.11-.783 1.654a131.865 131.865 0 0 1-3.865 7.229 121.646 121.646 0 0 1-2.82 4.65 114.384 114.384 0 0 1-3.036 4.512 108.252 108.252 0 0 1-4.98 6.49 102.95 102.95 0 0 1-5.541 6.143 100.326 100.326 0 0 1-8.346 7.613 102.212 102.212 0 0 1-4.613 3.547 105.703 105.703 0 0 1-4.928 3.365c-.395.254-.804.5-1.205.75h58.02c3.314 0 5.401-3.734 3.69-6.838L65.563 38.06z"
              color="#000"
            />
            <path
              fill="#4d4d4d"
              d="M48.242 8.563c-2.602 0-4.995 1.459-6.27 3.767L7.183 75.36a1.5 1.5 0 0 0-.001 0c-2.713 4.916.733 11.202 6.271 11.202h69.58c5.538 0 8.984-6.286 6.272-11.203L54.512 12.33c-1.275-2.308-3.668-3.767-6.27-3.768zm0 3c1.487 0 2.872.823 3.643 2.218l34.793 63.027c1.69 3.066-.371 6.754-3.645 6.754h-69.58c-3.273 0-5.335-3.688-3.644-6.754l34.79-63.027c.771-1.395 2.157-2.218 3.643-2.218z"
              color="#000"
            />
            <path
              d="M44.864 30.084v30.607h6.762V30.084Zm0 36.228v6.761h6.762v-6.761z"
              color="#000"
              style={{
                fill:'#4d4d4d',
                strokeWidth:2.2538,
              }}
            />
          </svg>
          <p>Ой, что-то пошло не так : ( <br></br>Если проблема сохранится, <br></br>обратитесь к разработчику.</p>
          <button onClick={resetErrorBoundary}>Обновить страницу</button>
        </div>
      </article>
    </div>
  );
}
