import React, {
  FC,
  HTMLAttributes,
  RefObject,
  useEffect,
  useState,
} from 'react';
import './Dropdown.scss';
import { createPortal } from 'react-dom';
import throttle from 'lodash.throttle';
import classNames from 'classnames';

interface DropdownProps extends HTMLAttributes<HTMLDivElement> {
  targetRef: RefObject<HTMLAnchorElement>;
  shown: boolean;
  onShownChange: (shown: boolean) => void;
  setAnimate: (shown: boolean) => void;
}

const calcCoordinates = (target: HTMLElement) => {
  const rect = target.getBoundingClientRect();

  return {
    top: window.scrollY + rect.bottom,
    right: window.innerWidth - rect.right,
  };
};

/**
 * Выпадающий список
 */
export const Dropdown: FC<DropdownProps> = ({
  children,
  targetRef,
  shown,
  onShownChange,
  setAnimate,
  className,
  style,
  ...restProps
}) => {
  const [coordinates, setCoordinates] = useState({ top: 0, right: 0 });

  useEffect(() => {
    setCoordinates(calcCoordinates(targetRef.current as HTMLElement));
  }, [targetRef]);

  useEffect(() => {
    onShownChange(shown);
  }, [onShownChange, shown]);

  useEffect(() => {
    const documentClickListener = () => {
      setAnimate(false);
      setTimeout(() => {
        onShownChange(false);
      }, 200);
    };

    const windowResizeListener = throttle(() => {
      setCoordinates(calcCoordinates(targetRef.current as HTMLElement));
    }, 10);

    if (shown) {
      setCoordinates(calcCoordinates(targetRef.current as HTMLElement));
      document.addEventListener('click', documentClickListener);
      document.addEventListener('scroll', documentClickListener, true);
      window.addEventListener('resize', windowResizeListener);
    }

    return () => {
      document.removeEventListener('click', documentClickListener);
      document.removeEventListener('scroll', documentClickListener, true);
      window.removeEventListener('resize', windowResizeListener);
    };
  }, [onShownChange, shown, targetRef]);

  return shown
    ? createPortal(
        <div
          {...restProps}
          className={classNames('dropdown', className)}
          style={{ ...style, ...coordinates }}
        >
          {children}
        </div>,
        document.getElementById('overlay') as HTMLDivElement,
      )
    : null;
};
