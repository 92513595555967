import React, { useEffect, useState } from 'react';
import { TRoute } from '../../App/routeConfig';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';

type BreadcrumbsProps = {
  pathname: string;
  routesList: TRoute[];
};

/**
 * Вспомогательный компонент хлебных крошек. Нужен для использования в хуке useRouteData.
 * Не следует его использовать. Хук useRouteData предоставляет компонент Crumbs, который
 * и нужно использовать.
 */
export const Breadcrumbs = ({ pathname, routesList }: BreadcrumbsProps) => {
  const [isPage404, setPage404] = useState(false);

  useEffect(() => {
    if (routesList.filter((route) => route.path === pathname).length > 0) {
      setPage404(false);
    } else {
      setPage404(true);
    }
  }, [pathname]);

  let currentLink = '';

  const crumbs = pathname
    .split('/')
    .filter((crumb) => crumb !== '')
    .map((crumb) => {
      currentLink += '/' + crumb;
      const route = routesList.find((item) => item.path === currentLink);

      if (route && route !== null) {
        return (
          <div className="breadcrumbs__crumb" key={currentLink}>
            <Link to={currentLink}>{route.title}</Link>
          </div>
        );
      }
    });

  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__crumb">
        <Link to={'/'}>Главная</Link>
      </div>
      {isPage404 ? (
        <div className="breadcrumbs__crumb" key={404}>
          Страница 404
        </div>
      ) : (
        crumbs
      )}
    </div>
  );
};
