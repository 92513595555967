import React, { useState } from 'react';
import { Checkbox } from '../../shared/Checkbox/Checkbox';
import '../../../assets/svg/ckecked.svg';
import { Question } from '../../shared/Question/Question';
import './Calculator.scss';

type CheckboxSetProps = {
  title: string;
  onCheck: (id: string) => void;
  onUncheck: (id: string) => void;
  data: {
    title: string;
    id: string;
    popup: string;
    shortTitle: string;
  }[] &
    Record<string, any>;
} & Record<string, any>;

export const CheckboxSet = ({
  data,
  title,
  onCheck,
  onUncheck,
}: CheckboxSetProps) => {
  return (
    <div className="calculator__block">
      <div className="calculator__block-title">{title}</div>
      <div className="calculator__block-checkboxes">
        {data.map(({ title, id, popup }) => (
          <div key={id} className="calculator__block-checkbox">
            <Checkbox
              onCheck={() => onCheck(id)}
              onUncheck={() => onUncheck(id)}
            >
              {title}
            </Checkbox>
            {popup && <Question popup={popup} />}
          </div>
        ))}
      </div>
    </div>
  );
};
