import React, { FC, HTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItemWithDropdown } from '../../../shared/MenuItemWithDropdown/MenuItemWithDropdown';
import { MenuItemCollapsible } from '../../../shared/MenuItemCollapsible/MenuItemCollapsible';
import { withBullet } from '../../../../hocs/withBullet';
import classNames from 'classnames';
import './NavLinks.scss';

interface NavlinksProps extends HTMLAttributes<HTMLUListElement> {
  area: 'header' | 'footer' | 'mobile-menu';
  onClose?: () => void;
}

const ElementWithBullet = withBullet(({ children }) => <>{children}</>);

/**
 * Навигационные ссылки
 */
export const NavLinks: FC<NavlinksProps> = ({ area, onClose }) => {
  const Submenu = () => (
    <ul className="submenu" onClick={onClose}>
      <li className="styled-list">
        <NavLink to={'/service/buhgalterskie-uslugi-dlya-ip'}>
          <ElementWithBullet>Бухгалтерские услуги для ИП</ElementWithBullet>
        </NavLink>
      </li>
      <li className="styled-list">
        <NavLink to={'/service/zapolnenie-deklaracii-3-ndfl'}>
          <ElementWithBullet>Заполнение декларации 3-НДФЛ</ElementWithBullet>
        </NavLink>
      </li>
    </ul>
  );

  const Element = () => {
    if (area === 'header') {
      return (
        <MenuItemWithDropdown>
          <Submenu />
        </MenuItemWithDropdown>
      );
    }

    if (area === 'mobile-menu') {
      return (
        <MenuItemCollapsible>
          <Submenu />
        </MenuItemCollapsible>
      );
    }

    return <NavLink to={'/service'}>Услуги</NavLink>;
  };

  return (
    <ul className={classNames('nav-links', { 'in-footer': area === 'footer' })}>
      <li className="nav-links__link">
        <NavLink to={'/'}>главная</NavLink>
      </li>
      <li>
        <Element />
      </li>
      <li>
        <NavLink to={'/price'}>прайс</NavLink>
      </li>
      <li>
        <NavLink to={'/calculator'}>калькулятор</NavLink>
      </li>
      <li>
        <NavLink to={'/news'}>новости</NavLink>
      </li>
      <li>
        <NavLink to={'/contacts'}>контакты</NavLink>
      </li>
    </ul>
  );
};
