import React, { CSSProperties, FC, HTMLAttributes } from 'react';
import { ImageStub } from './ImageStub/ImageStub';

interface NewsImageProps extends HTMLAttributes<HTMLImageElement> {
    src: string;
    alt: string;
    style?: CSSProperties
}

export const NewsImage: FC<NewsImageProps> = ({ src, alt, style }) => {
    return src 
        ? <img 
        src={src} 
        alt={alt} 
        style={{
            width: '100%',
            height: 'auto',
            marginBottom: 0,
            objectFit: 'cover',
            aspectRatio:'279 / 200',
            backgroundColor: '#dcdcdc',
            color: '#dcdcdc',
            ...style
        }}
        /> 
        : <ImageStub style={style} />
}