import { useQuery } from 'react-query';
import { getServicesForIndividuals } from '../restAPI';
import { TServicesForIndividuals, TRow } from '../types';

const staleTime = 60 * 60 * 1000;

const processData = (data: TServicesForIndividuals[]): Promise<TRow[]> =>
  new Promise((resolve, reject) => {
    try {
      const dataToArr: TRow[] = [];

      data.map(({ title, price, id }) => {
        dataToArr.push({ id, row: [title, `${price} руб`] });
      });

      resolve(dataToArr);
    } catch (e) {
      reject(e);
    }
  });

export const useProcessedServicesForIndividuals = () => {
  const { data: initialData } = useQuery({
    queryKey: ['services-for-individuals'],
    queryFn: getServicesForIndividuals,
    placeholderData: [],
    staleTime,
  });

  const { data: processedData } = useQuery({
    queryKey: ['processed-services-for-individuals'],
    queryFn: () => processData(initialData),
    placeholderData: [],
    staleTime,
    enabled: !!initialData.length,
  });

  return {
    isServicesForIndividualsLoading: !processedData.length,
    servicesForIndividuals: processedData,
  };
};
