import React from 'react';
import { Link } from 'react-router-dom';
import './SocialLinks.scss';
import { useYandexMetrika } from '../../../../hooks/useYandexMetrika';

const Icon = ({ icon }: { icon: string }) => {
  const Element = () => {
    if (icon === 'telegram') {
      return (
        <path
          className="telegram"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.864 23.7467C17.8605 20.6984 22.5259 18.6888 24.8603 17.7179C31.5253 14.9456 32.9103 14.4641 33.813 14.4482C34.0115 14.4447 34.4554 14.4939 34.743 14.7272C34.9858 14.9242 35.0526 15.1904 35.0845 15.3771C35.1165 15.5639 35.1563 15.9895 35.1247 16.322C34.7635 20.1169 33.2007 29.3263 32.4056 33.5767C32.0692 35.3752 31.4067 35.9783 30.7654 36.0373C29.3717 36.1655 28.3134 35.1162 26.9635 34.2314C24.8512 32.8467 23.6578 31.9848 21.6075 30.6336C19.2379 29.0721 20.774 28.2139 22.1244 26.8113C22.4778 26.4442 28.6186 20.8587 28.7374 20.352C28.7523 20.2886 28.7661 20.0524 28.6258 19.9277C28.4854 19.803 28.2783 19.8456 28.1289 19.8795C27.917 19.9276 24.5426 22.158 18.0055 26.5707C17.0477 27.2284 16.1801 27.5489 15.4028 27.5321C14.5459 27.5136 12.8975 27.0476 11.6721 26.6492C10.1691 26.1607 8.97456 25.9024 9.07858 25.0726C9.13276 24.6404 9.72791 24.1985 10.864 23.7467Z"
        />
      );
    }

    if (icon === 'viber') {
      return (
        <>
          <path
            className="viber"
            d="M33.062 14.4173C32.4399 13.8492 29.9259 12.0433 24.3261 12.0188C24.3261 12.0188 17.7226 11.6247 14.5035 14.5466C12.7116 16.3202 12.0812 18.9154 12.0147 22.1328C11.9482 25.3503 11.8622 31.38 17.7358 33.0149H17.7414L17.7376 35.5097C17.7376 35.5097 17.7001 36.5197 18.3722 36.7257C19.1851 36.9755 19.6622 36.2078 20.4383 35.3803C20.8643 34.926 21.4526 34.2588 21.8959 33.7487C25.913 34.0833 29.0025 33.3186 29.3533 33.2056C30.1644 32.9454 34.754 32.3632 35.5004 26.3343C36.2709 20.1198 35.1278 16.189 33.062 14.4173ZM33.7427 25.8886C33.1127 30.9227 29.3902 31.2391 28.7038 31.4569C28.4119 31.5499 25.6985 32.2179 22.2863 31.9974C22.2863 31.9974 19.7437 35.0327 18.9496 35.822C18.8256 35.9454 18.6798 35.9952 18.5825 35.9707C18.4458 35.9376 18.4082 35.7773 18.4097 35.5431C18.412 35.2086 18.4315 31.3974 18.4315 31.3974C18.4285 31.3974 18.4315 31.3974 18.4315 31.3974C13.4629 30.0324 13.7525 24.9005 13.8089 22.2131C13.8652 19.5258 14.3754 17.3243 15.8909 15.8436C18.6137 13.4031 24.2228 13.7678 24.2228 13.7678C28.9596 13.7883 31.2294 15.1998 31.7558 15.6726C33.5034 17.1533 34.3937 20.6964 33.7427 25.8871V25.8886Z"
          />
          <path
            className="viber"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.4223 20.0388C24.4314 19.8659 24.5804 19.7331 24.7551 19.7421C25.4086 19.7759 25.9522 19.9716 26.3395 20.3678C26.7252 20.7625 26.9143 21.3138 26.9475 21.976C26.9562 22.1488 26.8216 22.2959 26.6469 22.3045C26.4722 22.3131 26.3236 22.1799 26.3149 22.007C26.2865 21.4402 26.1293 21.0543 25.8842 20.8035C25.6405 20.5542 25.2687 20.3963 24.7221 20.368C24.5474 20.359 24.4132 20.2116 24.4223 20.0388Z"
          />
          <path
            className="viber"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9423 18.3503C23.955 18.1777 24.1068 18.048 24.2812 18.0607C25.6111 18.157 26.6816 18.6066 27.4613 19.4489L27.4614 19.449C28.2326 20.2829 28.5992 21.3204 28.5726 22.5372C28.5688 22.7102 28.424 22.8475 28.2491 22.8437C28.0743 22.84 27.9356 22.6967 27.9394 22.5236C27.9628 21.4505 27.6439 20.5747 26.9941 19.8721C26.3463 19.1724 25.4408 18.7731 24.235 18.6858C24.0606 18.6731 23.9295 18.5229 23.9423 18.3503Z"
          />
          <path
            className="viber"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3828 16.7453C23.384 16.5722 23.5268 16.4329 23.7017 16.4341C25.5396 16.4468 27.093 17.0522 28.3403 18.2554C29.598 19.4687 30.22 21.1204 30.2358 23.1718C30.2371 23.3449 30.0964 23.4862 29.9215 23.4876C29.7466 23.4889 29.6037 23.3496 29.6024 23.1766C29.5876 21.2543 29.0097 19.7764 27.8982 18.7042C26.7762 17.6219 25.3832 17.0725 23.6973 17.0608C23.5224 17.0596 23.3816 16.9184 23.3828 16.7453Z"
          />
          <path
            className="viber"
            d="M24.801 26.1888C24.801 26.1888 25.2462 26.226 25.4858 25.9338L25.9532 25.352C26.1786 25.0636 26.7225 24.8795 27.2552 25.1732C27.6544 25.3985 28.042 25.6434 28.4164 25.907C28.7699 26.1643 29.4939 26.7621 29.4961 26.7621C29.841 27.0502 29.9206 27.4732 29.6858 27.9193C29.6858 27.9219 29.684 27.9264 29.684 27.9286C29.4254 28.3722 29.097 28.7722 28.7113 29.1137C28.7068 29.1159 28.7068 29.1182 28.7027 29.1204C28.3676 29.3975 28.0384 29.555 27.7151 29.5929C27.6675 29.6011 27.6191 29.6041 27.5708 29.6018C27.4282 29.6032 27.2864 29.5816 27.1508 29.5379L27.1403 29.5226C26.6422 29.3836 25.8104 29.0356 24.4253 28.2795C23.6238 27.8471 22.8607 27.3483 22.1443 26.7884C21.7852 26.508 21.4426 26.2074 21.1183 25.8881L21.0837 25.8539L21.0492 25.8197L21.0146 25.7855C21.003 25.7743 20.9917 25.7628 20.9801 25.7513C20.6574 25.4304 20.3536 25.0914 20.0702 24.736C19.5045 24.0272 19.0004 23.2722 18.5634 22.4792C17.7993 21.1082 17.4476 20.2859 17.3071 19.7922L17.2917 19.7818C17.2477 19.6476 17.226 19.5073 17.2275 19.3662C17.2249 19.3184 17.2278 19.2705 17.2361 19.2234C17.2765 18.904 17.4359 18.578 17.7144 18.2454C17.7166 18.2413 17.7189 18.2413 17.7211 18.2368C18.0661 17.8552 18.4704 17.5304 18.9188 17.2748C18.921 17.2748 18.9255 17.2725 18.9282 17.2725C19.379 17.0402 19.8065 17.119 20.0972 17.4584C20.0995 17.4606 20.7024 18.177 20.9613 18.5268C21.2277 18.8977 21.4752 19.2816 21.7029 19.677C21.9996 20.2037 21.8137 20.7431 21.5222 20.9654L20.9342 21.4279C20.6375 21.6651 20.6765 22.1056 20.6765 22.1056C20.6765 22.1056 21.5473 25.3669 24.801 26.1888Z"
          />
        </>
      );
    }

    if (icon === 'whatsapp') {
      return (
        <path
          className="whatsapp"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.9104 26.1374C28.6438 26.004 27.333 25.359 27.0886 25.2699C26.8442 25.181 26.6664 25.1366 26.4887 25.4034C26.3109 25.6703 25.8 26.2709 25.6444 26.4488C25.4889 26.6267 25.3334 26.6491 25.0668 26.5156C24.8001 26.3821 23.941 26.1006 22.9226 25.1922C22.13 24.4852 21.5949 23.6121 21.4394 23.3452C21.2839 23.0782 21.4228 22.9339 21.5563 22.801C21.6762 22.6815 21.8229 22.4896 21.9562 22.3339C22.0896 22.1782 22.134 22.0669 22.2228 21.8891C22.3117 21.7111 22.2673 21.5554 22.2006 21.422C22.134 21.2886 21.6007 19.9761 21.3786 19.4422C21.1621 18.9224 20.9423 18.9928 20.7787 18.9845C20.6233 18.9768 20.4454 18.9752 20.2677 18.9752C20.0899 18.9752 19.801 19.0419 19.5566 19.3088C19.3123 19.5757 18.6235 20.2208 18.6235 21.5331C18.6235 22.8455 19.5788 24.1133 19.7122 24.2913C19.8455 24.4693 21.5922 27.1623 24.2669 28.3172C24.903 28.5919 25.3997 28.756 25.7869 28.8788C26.4256 29.0818 27.0068 29.0531 27.4663 28.9845C27.9785 28.908 29.0437 28.3395 29.2659 27.7168C29.4881 27.0938 29.4881 26.56 29.4214 26.4488C29.3548 26.3376 29.1771 26.2709 28.9104 26.1374ZM24.0456 32.7799H24.042C22.4505 32.7793 20.8895 32.3517 19.5278 31.5437L19.2039 31.3514L15.8472 32.232L16.7432 28.9592L16.5323 28.6236C15.6445 27.2115 15.1756 25.5794 15.1763 23.9036C15.1782 19.0136 19.1569 15.0352 24.0491 15.0352C26.418 15.036 28.6448 15.9597 30.3194 17.6362C31.9939 19.3126 32.9156 21.5409 32.9146 23.9107C32.9126 28.8012 28.934 32.7799 24.0456 32.7799ZM31.5939 16.3623C29.5792 14.3453 26.9 13.2341 24.0455 13.2329C18.1639 13.2329 13.3769 18.0195 13.3746 23.9029C13.3738 25.7836 13.8652 27.6193 14.799 29.2376L13.2852 34.7671L18.9419 33.2832C20.5005 34.1333 22.2553 34.5814 24.0412 34.582H24.0456C29.9265 34.582 34.7139 29.7949 34.7163 23.9115C34.7174 21.0602 33.6085 18.3793 31.5939 16.3623Z"
        />
      );
    }

    return null;
  };

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Element />
    </svg>
  );
};

/**
 * Компонент с иконками соцсетей
 */
export const SocialLinks = () => {
  const { reachGoal } = useYandexMetrika();

  return (
    <div className="social-icons">
      <span>Для связи с нами</span>
      {[
        { icon: 'telegram', link: 'https://t.me/buhetalon', cb: () => reachGoal('goToTelegram') },
        { icon: 'whatsapp', link: 'https://wa.me/79643422574', cb: () => reachGoal('goToWhatsApp') },
        { icon: 'viber', link: 'https://msng.link/o?79643422574=vi', cb: () => reachGoal('goToViber') },
      ].map(({ icon, link, cb }) => (
        <Link className="icon" to={link} key={icon} onClick={cb}>
          <Icon icon={icon} />
        </Link>
      ))}
    </div>
  );
};
