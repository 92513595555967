import React, { PropsWithChildren } from 'react';
import '../../../../assets/svg/document.svg';
import './PriceTableComponents.scss';
import { YesSign } from '../../../shared/YesSign/YesSign';
import { NoSign } from '../../../shared/NoSign/NoSign';
import { generateId } from '../../../../utils/generateId';
import { TRow } from '../../../../core/types';

type TPretext = {
  pretext: string[];
};

type TBodyProps = {
  data: TRow[];
  isLoading: boolean;
  maxNumberOfOperations?: number;
};

type TBodyMobileProps = {
  headers: string[];
} & TBodyProps;

type TFootnote = {
  footnote?: () => React.JSX.Element;
};

type TButton = {
  isOpen: boolean;
  onClick: () => void;
};

/**
 * Блок таблицы с заголовком, текстом, телом, сноками, кнопкой закрытия
 */
export const PriceTableComponents = ({ children }: PropsWithChildren) => (
  <div className="price-table">{children}</div>
);

/* Заголовок */
const Title = ({ children }: PropsWithChildren) => <h3>{children}</h3>;

/* Текст перед таблицей */
const Pretext = ({ pretext }: PropsWithChildren & TPretext) => {
  if (pretext?.length) {
    return (
      <div className="pretext">
        {pretext.map((item) => (
          <p key={item}>{item}</p>
        ))}
      </div>
    );
  }

  return null;
};

/* Заголовки столбцов */
const THead = ({ headers }: { headers: string[] }) => {
  return (
    <div className="thead">
      {headers.map((header) => (
        <div className="th" key={header}>
          {header}
        </div>
      ))}
    </div>
  );
};

/* Подзаголовок */
const Subtitle = ({ subtitle }: { subtitle: string }) => {
  return <h4>{subtitle}</h4>;
};

/* Подзаголовок в таблице */
const TableTitle = ({ tableTitle }: { tableTitle: string }) => {
  return (
    <div className="table-title">
      <svg>
        <use xlinkHref="#document"></use>
      </svg>
      {`Тариф "${tableTitle.toUpperCase()}"`}
    </div>
  );
};

/* Тело таблицы - десктопный вариант */
const TBody = ({ data, isLoading, maxNumberOfOperations }: TBodyProps) => {
  const genId = generateId('gen');

  if (isLoading) {
    return (
      <div className="tbody">
        <div className="td">Загрузка...</div>
      </div>
    );
  }

  return (
    <>
      {data.map(({ id, row }) => (
        <div key={id} className="tbody">
          {row.map((cell: string) => {
            if (typeof cell === 'string') {
              return (
                <div key={genId.getId()} className="td">
                  {cell}
                </div>
              );
            }

            if (typeof cell === 'boolean') {
              return (
                <div
                  key={genId.getId()}
                  className="td"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {cell ? <YesSign /> : <NoSign />}
                </div>
              );
            }
          })}
        </div>
      ))}
      {maxNumberOfOperations && (
        <div className="tbody">
          <div className="td">{`свыше ${maxNumberOfOperations} операций - цена договорная`}</div>
        </div>
      )}
    </>
  );
};

/* Тело таблицы - мобильный вариант */
const TBodyMobile = ({
  data,
  headers,
  isLoading,
  maxNumberOfOperations,
}: TBodyMobileProps) => {
  const Rows = () => {
    const genId = generateId('gen');

    return (
      <>
        {data.map(({ id, row }) => (
          <div key={id} className="tbody-mobile">
            {row.map((cell: string, index: number) => {
              if (typeof cell === 'string') {
                return (
                  <div key={genId.getId()} className="td-mobile">
                    <div>{headers[index]}</div>
                    {!!cell ? cell : '-'}
                  </div>
                );
              }

              if (typeof cell === 'boolean') {
                return (
                  <div key={genId.getId()} className="td-mobile">
                    <div>{headers[index]}</div>
                    {cell ? <YesSign /> : <NoSign />}
                  </div>
                );
              }
            })}
          </div>
        ))}
        {maxNumberOfOperations && (
          <div className="tbody-mobile">
            <div className="td-mobile">{`свыше ${maxNumberOfOperations} операций - цена договорная`}</div>
          </div>
        )}
      </>
    );
  };

  return isLoading ? (
    <div className="tbody">
      <div className="td">Загрузка...</div>
    </div>
  ) : (
    <Rows />
  );
};

/* Сноски */
const Footnote = ({ footnote }: PropsWithChildren & TFootnote) => {
  if (footnote) {
    return <div className="footnote">{footnote()}</div>;
  }

  return null;
};

/* Кнопка */
const Button = ({ isOpen, onClick, ...props }: TButton) => {
  return (
    <button {...props} className="button-mobile" onClick={onClick}>
      {isOpen ? 'Свернуть таблицу' : 'Показать цены'}
    </button>
  );
};

PriceTableComponents.Title = Title;
PriceTableComponents.Pretext = Pretext;
PriceTableComponents.THead = THead;
PriceTableComponents.TableTitle = TableTitle;
PriceTableComponents.Subtitle = Subtitle;
PriceTableComponents.TBody = TBody;
PriceTableComponents.TBodyMobile = TBodyMobile;
PriceTableComponents.Footnote = Footnote;
PriceTableComponents.Button = Button;
