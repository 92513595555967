import React, { useState } from 'react';
import { PriceTableComponents } from './PriceTableComponents';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import AnimateHeight from 'react-animate-height';
import { TRow } from '../../../../core/types';

type PriceTableProps = {
  isLoading: boolean;
  data: TRow[];
  headers: string[];
  title: string;
  pretext?: string[];
  footnote?: () => React.JSX.Element;
};

export const PriceTable = ({
  isLoading,
  data,
  headers,
  title,
  pretext,
  footnote,
}: PriceTableProps) => {
  const isDesktop = useMediaQuery('(min-width: 600px)');
  const [isOpen, setOpen] = useState(false);

  return (
    <PriceTableComponents>
      <PriceTableComponents.Title>{title}</PriceTableComponents.Title>
      {isDesktop ? (
        <>
          <PriceTableComponents.Pretext pretext={pretext} />
          <div className="table">
            <PriceTableComponents.THead headers={headers} />
            <PriceTableComponents.TBody data={data} isLoading={isLoading} />
          </div>
          <PriceTableComponents.Footnote footnote={footnote} />
        </>
      ) : (
        <>
          <AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
            <PriceTableComponents.Pretext pretext={pretext} />
            <div className="table">
              <PriceTableComponents.TBodyMobile
                data={data}
                isLoading={isLoading}
                headers={headers}
              />
            </div>
            <PriceTableComponents.Footnote footnote={footnote} />
          </AnimateHeight>
          <PriceTableComponents.Button
            isOpen={isOpen}
            onClick={() => setOpen((prev) => !prev)}
          />
        </>
      )}
    </PriceTableComponents>
  );
};
