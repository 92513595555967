import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import './Button.scss';

export type Theme = 'beige' | 'blue' | 'white';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  theme: Theme;
  mixin?: 'active' | 'disabled' | undefined;
}

// Базовый компонент кнопки
export const Button: FC<ButtonProps> = ({
  children,
  theme,
  mixin,
  ...restProps
}) => {
  const themeClass = `${theme}-theme`;

  return (
    <button {...restProps} className={classNames('button', themeClass, mixin)}>
      {children}
    </button>
  );
};
