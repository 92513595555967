import { useQuery } from 'react-query';
import {
  getLegalForm,
  getNumberOfOperations,
  getTariffPlan,
  getTariffs,
  getTaxationSystem,
  getActivityFeatures,
  getEmployeesSettings,
} from '../restAPI';
import {
  TTariff,
  TLegalForm,
  TTariffPlan,
  TNumberOfOperations,
  TTaxationSystem,
  TActivityFeatures,
  TEmployeesSettings,
  TCalculatorDataSet,
} from '../types';

type TData = {
  tariffs: { tariff: TTariff[] };
  legalForm: TLegalForm[];
  tariffPlan: TTariffPlan[];
  numberOfOperations: TNumberOfOperations[];
  taxationSystem: TTaxationSystem[];
  activityFeatures: TActivityFeatures[];
  employeesSettings: TEmployeesSettings;
};

const staleTime = 60 * 60 * 1000;

const processData = ({
  tariffs,
  legalForm,
  tariffPlan,
  numberOfOperations,
  taxationSystem,
  activityFeatures,
  employeesSettings,
}: TData): Promise<TCalculatorDataSet> =>
  new Promise((resolve, reject) => {
    try {
      const data = {
        legalForm,
        taxationSystem,
        numberOfOperations,
        maxNumberOfOperations: Math.max(
          ...(numberOfOperations as TNumberOfOperations[]).map(
            (operation) => operation.max,
          ),
        ),
        activityFeatures,
        employeesSettings,
        tariffPlan,
        // @ts-ignore
        tariffs: tariffs.tariff,
      };

      // @ts-ignore
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });

export const useProcessedCalculatorData = () => {
  const { data: tariffsData } = useQuery({
    queryKey: ['tariffs'],
    queryFn: getTariffs,
    staleTime,
  });

  const { data: legalFormData } = useQuery({
    queryKey: ['legal-form'],
    queryFn: getLegalForm,
    staleTime,
  });

  const { data: tariffPlanData } = useQuery({
    queryKey: ['tariff-plan'],
    queryFn: getTariffPlan,
    staleTime,
  });

  const { data: numberOfOperationsData } = useQuery({
    queryKey: ['number-of-operations'],
    queryFn: getNumberOfOperations,
    staleTime,
  });

  const { data: taxationSystemData } = useQuery({
    queryKey: ['taxation-system'],
    queryFn: getTaxationSystem,
    staleTime,
  });

  const { data: activityFeaturesData } = useQuery({
    queryKey: ['activity-features'],
    queryFn: getActivityFeatures,
    staleTime,
  });

  const { data: employeesSettingsData } = useQuery({
    queryKey: ['employees-settings'],
    queryFn: getEmployeesSettings,
    staleTime,
  });
  const { data: processedData } = useQuery({
    queryKey: ['processed-calculator-data'],
    queryFn: () =>
      processData({
        tariffs: tariffsData,
        legalForm: legalFormData,
        tariffPlan: tariffPlanData,
        numberOfOperations: numberOfOperationsData,
        taxationSystem: taxationSystemData,
        activityFeatures: activityFeaturesData,
        employeesSettings: employeesSettingsData,
      }),
    placeholderData: null,
    staleTime,
    enabled:
      !!tariffsData?.tariff &&
      !!legalFormData?.length &&
      !!tariffPlanData?.length &&
      !!numberOfOperationsData?.length &&
      !!taxationSystemData?.length &&
      !!activityFeaturesData?.length &&
      !!employeesSettingsData,
  });

  return {
    calculatorData: processedData,
    isCalculatorDataLoadings: !processedData,
  };
};
