import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PublicLayout } from '../public/PublicLayout/PublicLayout';
import { Service } from '../public/Service/Service';
import { Price } from '../public/Price/Price';
import { Calculator } from '../public/Calculator/Calculator';
import { Main } from '../public/Main/Main';
import { News } from '../public/News/News';
import { NewsItem } from '../public/NewsItem/NewsItem';
import { Contacts } from '../public/Contacts/Contacts';
import { NotFound } from '../public/NotFound/NotFound';
import { MainPageLayout } from '../public/PublicLayout/MainPageLayout';
import { CallbackFormContextProvider } from '../shared/Callback/CallbackFormContext';
import { Service1 } from '../public/ServiceItem/Service1/Service1';
import { Service2 } from '../public/ServiceItem/Service2/Service2';
import { Policy } from '../public/Policy/Policy';
import { QueryClient, QueryClientProvider } from 'react-query';
const Admin = lazy(() => import('./Admin'));

const queryClient = new QueryClient();

export const Public = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    const href = location.href;

    ym(97390539, 'hit', href);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/">
        <Route
          path="/admin/*"
          element={
            <Suspense fallback={<p>Loading...</p>}>
              <Admin />
            </Suspense>
          }
        />

        <Route
          element={
            <CallbackFormContextProvider>
              <MainPageLayout />
            </CallbackFormContextProvider>
          }
        >
          <Route element={<Main />} index />
        </Route>

        <Route
          element={
            <QueryClientProvider client={queryClient}>
              <CallbackFormContextProvider>
                <PublicLayout />
              </CallbackFormContextProvider>
            </QueryClientProvider>
          }
        >
          <Route path="service">
            <Route element={<Service />} index />
            <Route path="buhgalterskie-uslugi-dlya-ip" element={<Service1 />} />
            <Route path="zapolnenie-deklaracii-3-ndfl" element={<Service2 />} />
          </Route>
          <Route path="calculator" element={<Calculator />} />
          <Route path="price" element={<Price />} />
          <Route path="news">
            <Route element={<News />} index />
            <Route path=":id" element={<NewsItem />} />
          </Route>
          <Route path="contacts" element={<Contacts />} />
          <Route path="policy" element={<Policy />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};
