import React, { useState, memo } from 'react';
import { Button } from '../../shared/Button/Button';
import './Calculator';

type ButtonSetProps = {
  title: string;
  id: string;
  data: {
    title: string;
    id: string;
  }[] &
    Record<string, any>;
  cb: (id: string) => void;
} & Record<string, any>;

//TODO: нужно передалать ввод на input типа radio
export const ButtonSet = memo(({ data, title, cb, id }: ButtonSetProps) => {
  const currentIndex = data.findIndex((item) => item.id === id);
  const [index, setIndex] = useState(currentIndex > -1 ? currentIndex : 0);

  const handleClick = (i: number) => {
    setIndex(i);
    cb(data[i].id);
  };

  return (
    <div className="calculator__block">
      <div className="calculator__block-title">{title}</div>
      <div className="calculator__block-buttons">
        {data.map(({ title }, i) => (
          <div className="calculator__block-btn" key={title}>
            <Button
              mixin={i === index ? 'active' : undefined}
              theme="white"
              onClick={() => handleClick(i)}
            >
              {title}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
});

ButtonSet.displayName = 'ButtonSet';
