import React, { useEffect, useState } from 'react';
import mainScreenBackgroundImage1 from '../../../assets/images/Фон_1920x727.png';
import mainScreenBackgroundImage2 from '../../../assets/images/Фон_3840x1454.png';
import mainScreenBackgroundPhoto1 from '../../../assets/images/photo_418x651.png';
import mainScreenBackgroundPhoto2 from '../../../assets/images/photo_836x1122.png';
import diagram1 from '../../../assets/images/diagram_540x353.png';
import diagram2 from '../../../assets/images/diagram_1080x706.png';
import ourAdvantages1 from '../../../assets/images/фон_1920x1096.png';
import ourAdvantages2 from '../../../assets/images/фон_3940x2192.png';
import '../../../assets/svg/quotes.svg';
import '../../../assets/svg/curved-to-bottom-arrow.svg';
import '../../../assets/svg/curved-to-top-arrow.svg';
import '../../../assets/svg/clipboard.svg';
import '../../../assets/svg/case.svg';
import '../../../assets/svg/check.svg';
import '../../../assets/svg/documents.svg';
import '../../../assets/svg/message.svg';
import '../../../assets/svg/settings.svg';
import '../../../assets/svg/money.svg';
import { Footer } from './Footer/Footer';
import { Button } from '../../shared/Button/Button';
import { Carousel } from './Carousel/Carousel';
import { BackToTopButton } from '../../shared/BackToTopButton/BackToTopButton';
import { HeaderWithMobileMenu } from '../../shared/HeaderWithMobileMenu/HeaderWithMobileMenu';
import { useCallbackForm } from '../../shared/Callback/CallbackFormContext';
import { CallbackFormsSet } from '../../shared/Callback/CallbackFormsSet';
import { useYandexMetrika } from '../../../hooks/useYandexMetrika';
import './MainPageLayout.scss';

/**
 * Главная страница
 */
export const MainPageLayout = () => {
  const { reachGoal } = useYandexMetrika();
  const [matches, setMatches] = useState(
    window.matchMedia('(min-resolution: 2dppx)').matches,
  );

  const { setCallbackFormOpen } = useCallbackForm();

  useEffect(() => {
    window
      .matchMedia('(min-resolution: 2dppx)')
      .addEventListener('change', (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    document.title='Главная - Компания БухЭталонЪ';
  }, []);

  return (
    <div className="main-page">
      <section
        className="main-page__main-screen"
        style={{
          backgroundColor: '#0659ab',
          background: `left / cover no-repeat url(${
            matches ? mainScreenBackgroundImage2 : mainScreenBackgroundImage1
          })`,
        }}
      >
        <HeaderWithMobileMenu />
        <CallbackFormsSet />
        <div className="container">
          <div className="main-banner">
            <div className="main-banner__left-column">
              <h1>Бухгалтерское Обслуживание ООО и ИП по Всей России</h1>
              <span>
                Команда профессионалов окажет услуги в области бухгалтерского,
                налогового и кадрового учёта. Мы поможем с ведением первичной
                документации и подготовим к сдаче отчётность.
              </span>
              <Button theme="beige" onClick={() => {setCallbackFormOpen(),reachGoal('openCallbackForm')}}>
                Начать сотрудничество
              </Button>
            </div>
            <div></div>
            <div
              className="main-banner__right-column"
              style={{
                backgroundImage: `url(${
                  matches
                    ? mainScreenBackgroundPhoto2
                    : mainScreenBackgroundPhoto1
                })`,
              }}
            >
              <p>
                Сегодня иметь в штате бухгалтера невыгодно. Затраты на рабочее
                место, оплату труда, налоги и т.д. — это главные минусы.
                <div className="icon">
                  <svg>
                    <use xlinkHref="#quotes"></use>
                  </svg>
                </div>
              </p>
              <p>
                В большинстве случаев рациональнее и выгоднее иметь сотрудника
                на аутсорсинге.
              </p>
              <p>
                Сергей Арефьев, <br></br>директор компании
                <div className="icon">
                  <svg>
                    <use xlinkHref="#quotes"></use>
                  </svg>
                </div>
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </section>

      <section className="main-page__how-to-start">
        <div className="container">
          <h2>Как начать работать с нами</h2>
          <div className="steps">
            <div className="steps__step-even">
              <div className="steps__step-count">1 </div>
              <div className="steps__step-text">
                <h5>Обращение к нам</h5>
                <p>
                  Вы звоните нам по телефону +7&nbsp;(812)&nbsp;642-25-74
                  или&nbsp;оставляете&ensp;
                  <span onClick={() => {setCallbackFormOpen(),reachGoal('openCallbackForm')}}>
                    заявку&nbsp;на&nbsp;сайте
                  </span>
                </p>
              </div>
              <div className="icon steps__step-icon">
                <svg>
                  <use xlinkHref="#curved-to-bottom-arrow"></use>
                </svg>
              </div>
            </div>

            <div className="steps__step-odd">
              <div className="steps__step-count">2 </div>
              <div className="steps__step-text">
                <h5>Встреча и анализ бизнеса</h5>
                <p>
                  Мы обсуждаем текущую ситуацию в Вашей компании, виды и объемы
                  операций и предлагаем Вам варианты решения Ваших задач
                </p>
              </div>
              <div className="icon steps__step-icon">
                <svg>
                  <use xlinkHref="#curved-to-top-arrow"></use>
                </svg>
              </div>
            </div>

            <div className="steps__step-even">
              <div className="steps__step-count">3 </div>
              <div className="steps__step-text">
                <h5>Подписание договора</h5>
                <p>
                  Мы подписываем с Вами договор и соглашение
                  о конфиденциальности, которые гарантируют сохранность Вашей
                  информации
                </p>
              </div>
              <div className="icon steps__step-icon">
                <svg>
                  <use xlinkHref="#curved-to-bottom-arrow"></use>
                </svg>
              </div>
            </div>

            <div className="steps__step-odd">
              <div className="steps__step-count">4 </div>
              <div className="steps__step-text">
                <h5>Начало работ</h5>
                <p>Мы принимаем от Вас документы и определяем график работы</p>
              </div>
              <div className="icon steps__step-icon"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="main-page__about-company">
        <div className="container">
          <h2>О Компании БухЭталонЪ</h2>
          <div className="content">
            <div className="content__text">
              <p>
                В 1893 году Дмитрий Михайлович Менделеев возглавил Главную
                Палату Мер и Весов, в задачи которой входило хранение и
                изготовление образцов веса и мер.
              </p>
              <p>
                На логотипе компании изображён знак суммы — символ
                бухгалтерского учёта, помещённый в стеклянную колбу из Палаты
                Мер и Весов, предназначенную для хранения эталонных образцов.
                Логотип компании недвусмысленно указывает на эталонное качество
                оказываемых услуг.
              </p>
            </div>
            <div></div>
            <div className="content__image">
              <img src={`${matches ? diagram2 : diagram1}`} alt="диаграмма" />
            </div>
          </div>
        </div>
      </section>

      <section className="main-page__quote">
        <div className="container">
          <div className="quote">
            <div className="icon">
              <svg>
                <use xlinkHref="#clipboard"></use>
              </svg>
            </div>
            <div className="quote__text">
              Я работаю в этой области с 2007 года и точно знаю, как нужно
              делать работу, цель которой показать государственным органам, что
              вы работаете правильно
            </div>
            <div className="quote__surname">
              Сергей Арефьев, директор компании
              <div className="icon">
                <svg>
                  <use xlinkHref="#quotes"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="main-page__our-advantages"
        style={{
          background: `left / cover no-repeat url(${
            matches ? ourAdvantages2 : ourAdvantages1
          })`,
        }}
      >
        <div className="container">
          <div className="advantages__preview">
            <p>
              <span className="beige">Компания БухЭталонЪ</span> — это посредник
              между Вами и государственными органами. Очень важно, как Вы будете
              выглядеть в глазах налоговой службы и других инстанций.
              Это впечатление формируется по содержимому в декларациях
              и отчетах.
            </p>
            <p>
              <span className="beige">Доверьте нам Ваш учёт</span> и мы
              представим Вас в эталонном виде. Это исключит лишние вопросы у
              госорганов и повысит рейтинг вашего бизнеса.
            </p>
          </div>
          <h2>Наши преимущества</h2>
          <div className="advantages">
            <div className="advantages__advantage">
              <div className="icon">
                <svg>
                  <use xlinkHref="#case"></use>
                </svg>
              </div>
              <h5>Более 10 лет работы</h5>
              <div className="advantages__advantage-text">
                Накопленный профессиональный опыт позволяет избежать типичные
                бухгалтерские ошибки и проблемы у наших клиентов
              </div>
            </div>

            <div className="advantages__advantage">
              <div className="icon">
                <svg>
                  <use xlinkHref="#check"></use>
                </svg>
              </div>
              <h5>Ответственность</h5>
              <div className="advantages__advantage-text">
                Мы несём полную финансовую ответственность перед клиентом за
                проделанную работу
              </div>
            </div>

            <div className="advantages__advantage">
              <div className="icon">
                <svg>
                  <use xlinkHref="#documents"></use>
                </svg>
              </div>
              <h5>Работа по стандартам</h5>
              <div className="advantages__advantage-text">
                Все наши специалисты работают по отработанной схеме ведения
                учета. Указанная схема позволяет перепроверять данные и тем
                самым избежать ошибок. Все бухгалтерские операции, даже
                первичная документация, вводятся по определённым, отработанным
                лекалам
              </div>
            </div>

            <div className="advantages__advantage">
              <div className="icon">
                <svg>
                  <use xlinkHref="#money"></use>
                </svg>
              </div>
              <h5>Экономия затрат</h5>
              <div className="advantages__advantage-text">
                За счет отсутствия штатного специалиста, вы экономите
                на компьютере, МФУ и их обслуживании, на программном
                обеспечении, на оплате труда, налогах с з/п, на аренде офиса для
                бухгалтерии и т.п.
              </div>
            </div>

            <div className="advantages__advantage">
              <div className="icon">
                <svg>
                  <use xlinkHref="#message"></use>
                </svg>
              </div>
              <h5>Всегда на связи</h5>
              <div className="advantages__advantage-text">
                Принцип взаимозаменяемости позволяет нам всегда быть на связи
                для клиента. Для Вас — у нас нет отпуска и больничных листов, мы
                всегда на связи
              </div>
            </div>

            <div className="advantages__advantage">
              <div className="icon">
                <svg>
                  <use xlinkHref="#settings"></use>
                </svg>
              </div>
              <h5>Гибкая тарифная сетка</h5>
              <div className="advantages__advantage-text">
                Клиент может подобрать тот вариант обслуживания, который
                соответствует его запросам и финансовым возможностям
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main-page__they-trust-us">
        <div className="container">
          <h2>Нам доверяют</h2>
        </div>
        <Carousel />
      </section>

      <Footer />

      <BackToTopButton />
    </div>
  );
};
