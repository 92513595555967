import React from 'react';
import { useRouteData } from '../../../hooks/useRouteData';
import './Policy.scss';

export const Policy = () => {
  const { Crumbs, title } = useRouteData();

  return (
    <>
      <div className="container">
        <Crumbs />
        <h2>{title}</h2>
      </div>

      <div className="container">
        <article className="article">
          <p>
            Пользовательское соглашение на обработку персональных данных на
            сайте БухЭталонЪ (www.buhetalon.ru) – бухгалтерские услуги по всей
            России.
          </p>
          <p>
            Предоставляя свои персональные данные, Пользователь даёт согласие на
            обработку, хранение и использование своих персональных данных на
            основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в
            следующих целях:
          </p>
          <ul>
            <li>осуществление клиентской поддержки</li>
            <li>осуществление бухгалтерского и юридического сопровождения</li>
            <li>получения Пользователем информации о маркетинговых событиях</li>
            <li>
              проведения аудита и прочих внутренних исследований с целью
              повышения качества предоставляемых услуг
            </li>
          </ul>
          <p>
            Под персональными данными подразумевается любая информация личного
            характера, позволяющая установить личность Пользователя/Покупателя
            такая как:
          </p>
          <ul>
            <li>Фамилия, Имя, Отчество</li>
            <li>контактный телефон</li>
            <li>адрес электронной почты</li>
            <li>системные файлы cookies</li>
          </ul>
          <p>
            Персональные данные Пользователей хранятся исключительно на
            электронных носителях и обрабатываются с использованием
            автоматизированных систем, за исключением случаев, когда
            неавтоматизированная обработка персональных данных необходима в
            связи с исполнением требований законодательства.
          </p>
          <ul>
            <li>
              по запросам уполномоченных органов государственной власти РФ
              только по основаниям и в порядке, установленным законодательством
              РФ
            </li>
            <li>
              стратегическим партнерам, которые работают с Компанией для
              предоставления продуктов и услуг, или тем из них, которые помогают
              Компании реализовывать продукты и услуги потребителям. Мы
              предоставляем третьим лицам минимальный объем персональных данных,
              необходимый только для оказания требуемой услуги или проведения
              необходимой транзакции.
            </li>
          </ul>
          <p>
            Специалисты из БухЭталонЪ оставляют за собой право вносить изменения
            в одностороннем порядке в настоящие правила, при условии, что
            изменения не противоречат действующему законодательству РФ.
            Изменения условий настоящих правил вступают в силу после их
            публикации на Сайте.
          </p>
          <p></p>
        </article>
      </div>
    </>
  );
};
