import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { ButtonSet } from './ButtonSet';
import { Range } from './Range';
import { CheckboxSet } from './CheckboxSet';
import { TariffSet } from './TariffSet';
import { initialState, reducer } from './reducer';
import { useProcessedMoreAboutTariffs } from '../../../core/query-hooks/useProcessedMoreAboutTariffs';
import { PriceTable } from '../Price/PriceTable/PriceTable';
import AnimateHeight from 'react-animate-height';
import { TCalculatorDataSet } from '../../../core/types';
import './Calculator.scss';

type CalculatorBodyProps = {
  dataSet: TCalculatorDataSet;
};

export const CalculatorBody = ({ dataSet }: CalculatorBodyProps) => {
  const { isMoreAboutTariffsLoading, moreAboutTariffs } =
    useProcessedMoreAboutTariffs();
  const [
    {
      data: {
        legalForm,
        taxationSystem,
        maxNumberOfOperations,
        activityFeatures,
        tariffPlan,
      },
      currentValues: {
        legalFormId,
        taxationSystemId,
        minNumberOfEmployees,
        maxNumberOfEmployees,
      },
      tariffSet,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const [isTableShown, setTableShown] = useState(false);

  useEffect(() => {
    if (dataSet) {
      dispatch({ type: 'set_initial_state', payload: { ...dataSet } });
    }
  }, [dataSet]);

  return (
    <AnimateHeight duration={1000} height={'auto'}>
      <div className="calculator">
        <div className="container">
          <ButtonSet
            data={legalForm}
            title="Правовая форма"
            cb={(id) => dispatch({ type: 'on_legal_form_change', payload: id })}
            id={legalFormId}
          />
          <ButtonSet
            data={taxationSystem}
            title="Система налогооблажения"
            cb={(id) =>
              dispatch({ type: 'on_taxation_system_change', payload: id })
            }
            id={taxationSystemId}
          />
          <Range
            min={0}
            max={maxNumberOfOperations}
            cb={useCallback(
              (value) =>
                dispatch({
                  type: 'on_number_of_operations_change',
                  payload: value,
                }),
              [],
            )}
            title="Количество операций"
            popup={
              '<article class="article"><p>Под "операцией" понимается отражение в программе 1С одного события из следующих:</p><ul><li> операция по поступлению товаров/услуг (комплект документов)</li><li> операция по реализации товаров/услуг (комплект документов)</li><li> операция по проведению одной банковской проводки (кроме платежей в бюджет и комиссии банка)</li><li> составление путевого листа</li><li> регистрация одного документа в авансовом отчёте</li></ul><p>В зависимости от деятельности организации возможны иные виды операций и первичных документов.</p></article>'
            }
          />
          <Range
            min={minNumberOfEmployees}
            max={maxNumberOfEmployees}
            title="Количество сотрудников в штате"
            cb={useCallback(
              (value) =>
                dispatch({
                  type: 'on_number_of_employees_change',
                  payload: value,
                }),
              [],
            )}
          />
          <CheckboxSet
            onCheck={(id) =>
              dispatch({ type: 'on_add_activity_feature_id', payload: id })
            }
            onUncheck={(id) =>
              dispatch({ type: 'on_remove_activity_feature_id', payload: id })
            }
            data={activityFeatures}
            title={'Особенности деятельности'}
          />
        </div>
        <div className="full-screen">
          <TariffSet data={tariffSet} />

          <div className="container">
            <div className="disclaimer">
              Примечание. На стоимость обслуживания так же могут повлиять такие
              факторы, как количество «рабочих» р/сч, в т.ч. валютных,
              восстановление учета прошлого периода, выезд в офис клиента,
              консультирование по вопросам бухгалтерского и налогового учета,
              ответ в ФНС на требование, в т.ч. сбор необходимых документов,
              составление дополнительных отчётов по просьбе клиента, проведение
              сверки с контрагентами и прочие услуги. Подробнее с ценами на
              услуги можно ознакомиться в Прайс-Листе.
            </div>
            {!isTableShown && (
              <div className="show-table__button">
                <button onClick={() => setTableShown(true)}>
                  Подробнее о тарифах
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <AnimateHeight duration={500} height={isTableShown ? 'auto' : 0}>
        <div className="container">
          <article className="article">
            <PriceTable
              data={moreAboutTariffs}
              isLoading={isMoreAboutTariffsLoading}
              title="Подробнее о тарифах"
              headers={[
                'Наименование услуги',
                ...tariffPlan.map((tariff) => tariff.title),
                'Доп. услуга к тарифу',
                'Единица измерения',
              ]}
              footnote={() => (
                <>
                  <p>
                    * Первичный документ - это документ, на основании которого
                    формируется поступление или реализация товаров, работ услуг.
                    Первичным документом может быть:
                  </p>
                  <ul className="footnote__list">
                    <li>товарная накладная (ТОРГ12); счёт-фактура</li>
                    <li>УПД (Универсальный передаточный документ)</li>
                    <li>акт выполненных работ</li>
                    <li>отчет комиссионера, комитента или экспедитора</li>
                    <li>прочие первичные документы</li>
                  </ul>
                  <p>
                    ** Наличие и условия (тарифы) электронного документооборота
                    (ЭДО) оговариваются отдельно и зависят от провайдера, с
                    которым у Клиента заключен договор на ЭДО. ООО
                    &quot;БухЭталонЪ&quot; не берёт дополнительную плату к
                    тарификации ЭДО, цены и условия устанавливает провайдер.
                  </p>
                  <p>
                    *** Один комплект документов - это комплект, который
                    относится к одной конкретной сделке по поступлению или
                    реализации. Например, одним комплектом документов по продаже
                    товара может быть: счёт, товарная накладная ТОРГ12 и
                    счёт-фактура. Договор, спецификации и приложения к нему
                    считаются одним комплектом.
                  </p>
                </>
              )}
            />

            <div className="show-table__button">
              <button onClick={() => setTableShown(false)}>Свернуть</button>
            </div>
          </article>
        </div>
      </AnimateHeight>
    </AnimateHeight>
  );
};
