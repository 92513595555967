import { TCalculatorDataSet } from '../../../core/types';

type CurrentValues = {
  legalFormId: string;
  taxationSystemId: string;
  numberOfOperationsValue: number;
  activityFeaturesId: string[];
  tariffPlanId: string;
  numberOfEmployees: number;
  costPerEmployee: number;
  minNumberOfEmployees: number;
  maxNumberOfEmployees: number;
};

type TTariffSet = {
  title: string;
  price: string;
};

type InitialState = {
  data: TCalculatorDataSet;
  currentValues: CurrentValues;
  tariffSet: TTariffSet[];
};

export const initialState: InitialState = {
  data: {
    legalForm: [],
    taxationSystem: [],
    numberOfOperations: [],
    maxNumberOfOperations: 0,
    activityFeatures: [],
    tariffPlan: [],
    tariffs: [],
    employeesSettings: null,
  },
  currentValues: {
    legalFormId: '',
    taxationSystemId: '',
    numberOfOperationsValue: 0,
    activityFeaturesId: [],
    tariffPlanId: '',
    numberOfEmployees: 1,
    costPerEmployee: 0,
    minNumberOfEmployees: 1,
    maxNumberOfEmployees: 1,
  },
  tariffSet: [
    {
      title: '',
      price: '',
    },
  ],
};

export type Action = {
  type:
    | 'set_initial_state'
    | 'on_legal_form_change'
    | 'on_taxation_system_change'
    | 'on_number_of_operations_change'
    | 'on_number_of_employees_change'
    | 'on_add_activity_feature_id'
    | 'on_remove_activity_feature_id';
  payload: any;
};

const getTariffSet = (
  data: TCalculatorDataSet,
  currentValues: CurrentValues,
) => {
  const { numberOfOperations, tariffPlan, tariffs } = data;
  const { legalFormId, taxationSystemId, numberOfOperationsValue } =
    currentValues;

  return tariffPlan.map((tariff) => {
    let price = '';

    // находим тарифную сетку для нужной юридической формы && тарифного плана
    const tariffSubsectionItem = tariffs.find(
      (item) =>
        item.legalFormId === legalFormId && item.tariffPlanId === tariff.id,
    );

    if (tariffSubsectionItem) {
      // определяем нужный пункт "количества операций" в numberOfOperations,
      // к которому относится значение из Range
      const numberOfOperationsCurrentItem = numberOfOperations.find(
        ({ min, max }) =>
          numberOfOperationsValue >= min && max >= numberOfOperationsValue,
      );

      if (numberOfOperationsCurrentItem) {
        // находим цены для соответствующего "количества операций"
        const numberOfOperationsItem = tariffSubsectionItem.data.find(
          ({ numberOfOperationsId }) =>
            numberOfOperationsId === numberOfOperationsCurrentItem.id,
        );

        if (numberOfOperationsItem) {
          // находим нужный тариф по системе налогообложения
          const tariffItem = numberOfOperationsItem.data_1.find(
            (tariff) => tariff.taxationSystemId === taxationSystemId,
          );

          if (tariffItem) {
            price = tariffItem.value.toString();
          }
        }
      }
    }

    const features = currentValues.activityFeaturesId
      .map((featureId) => {
        const feature = data.activityFeatures.find(
          ({ id }) => featureId === id,
        );

        if (feature) return +feature.percent / 100;
      })
      .reduce((sum, current) => sum + current, 0);

    return {
      title: tariff.title,
      price: (
        +price +
        currentValues.numberOfEmployees * currentValues.costPerEmployee +
        +price * features
      ).toString(),
    };
  });
};

export const reducer = (state: InitialState, action: Action) => {
  switch (action.type) {
    case 'set_initial_state': {
      const currentValues = {
        legalFormId: action.payload.legalForm[0].id,
        taxationSystemId: action.payload.taxationSystem[0].id,
        numberOfOperationsValue: 0,
        activityFeaturesId: [] as string[],
        tariffPlanId: action.payload.tariffPlan[0].id,
        numberOfEmployees: +action.payload.employeesSettings.settings.min.value,
        costPerEmployee: +action.payload.employeesSettings.settings.price.value,
        minNumberOfEmployees:
          +action.payload.employeesSettings.settings.min.value,
        maxNumberOfEmployees:
          +action.payload.employeesSettings.settings.max.value,
      };

      return {
        ...state,
        data: action.payload as TCalculatorDataSet,
        currentValues,
        tariffSet: getTariffSet(action.payload, currentValues),
      };
    }

    case 'on_legal_form_change': {
      const currentValues = {
        ...state.currentValues,
        legalFormId: action.payload,
      };

      return {
        ...state,
        currentValues,
        tariffSet: getTariffSet(state.data, currentValues),
      };
    }

    case 'on_taxation_system_change': {
      const currentValues = {
        ...state.currentValues,
        taxationSystemId: action.payload,
      };

      return {
        ...state,
        currentValues,
        tariffSet: getTariffSet(state.data, currentValues),
      };
    }

    case 'on_number_of_operations_change': {
      const currentValues = {
        ...state.currentValues,
        numberOfOperationsValue: action.payload,
      };

      return {
        ...state,
        currentValues,
        tariffSet: getTariffSet(state.data, currentValues),
      };
    }

    case 'on_number_of_employees_change': {
      const currentValues = {
        ...state.currentValues,
        numberOfEmployees: action.payload,
      };

      return {
        ...state,
        currentValues,
        tariffSet: getTariffSet(state.data, currentValues),
      };
    }

    case 'on_add_activity_feature_id': {
      const currentValues = {
        ...state.currentValues,
        activityFeaturesId: [
          ...new Set([
            ...state.currentValues.activityFeaturesId,
            action.payload,
          ]),
        ],
      };

      return {
        ...state,
        currentValues,
        tariffSet: getTariffSet(state.data, currentValues),
      };
    }

    case 'on_remove_activity_feature_id': {
      const currentValues = {
        ...state.currentValues,
        activityFeaturesId: state.currentValues.activityFeaturesId.filter(
          (item) => item !== action.payload,
        ),
      };

      return {
        ...state,
        currentValues,
        tariffSet: getTariffSet(state.data, currentValues),
      };
    }

    default:
      return state;
  }
};
