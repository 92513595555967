import { useQuery } from 'react-query';
import { getNews } from '../restAPI';
import { TNews } from '../types';

const staleTime = 60 * 60 * 1000;

type TProcessedData = Record<'title'|'date'|'src'|'link'|'alt'|'id', string>

const processData = (data: TNews[]): Promise<any> =>
  new Promise((resolve, reject) => {
    try {
      const dataToArr: TProcessedData[] = [];

      data.map(({ title, link, date, image, id }) => {
        dataToArr.push({ title, link, date, id, src: image?.src, alt: image?.alt });
      });

      resolve(dataToArr);
    } catch (e) {
      reject(e);
    }
  });

export const useNews = () => {
  const { data: initialData } = useQuery({
    queryKey: ['news'],
    queryFn: getNews,
    placeholderData: [],
    staleTime,
  });

  const { data: processedData } = useQuery({
    queryKey: ['processed-news'],
    queryFn: () => processData(initialData),
    placeholderData: [],
    staleTime,
    enabled: !!initialData.length,
  });

  return {
    isNewsLoading: !initialData.length,
    news: processedData as TProcessedData[],
  };
};
