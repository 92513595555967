type Input = { value: string; isValid?: boolean };
type InitialState = Record<'name' | 'phone' | 'message', Input> & {
  isCaptchaPassed: boolean;
};

export const initialState: InitialState = {
  name: { value: '', isValid: false },
  phone: { value: '', isValid: false },
  message: { value: '' },
  isCaptchaPassed: false,
};

type Action = {
  type:
    | 'set_name_value'
    | 'set_phone_value'
    | 'set_message_value'
    | 'reset_form'
    | 'set_captcha_passed';
  payload?: string;
};

const regexp = new RegExp(
  /^(\+7|7|8)?[\s\-]?\(?[3489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
  'ig',
);

export const createInitialState = (
  formFieldsData?: Record<'name' | 'phone' | 'message', string>,
) => {
  if (formFieldsData) {
    return {
      name: {
        value: formFieldsData ? formFieldsData?.name : '',
        isValid: !!formFieldsData?.name.length,
      },
      phone: {
        value: formFieldsData ? formFieldsData?.phone : '',
        isValid: regexp.test(formFieldsData?.phone),
      },
      message: {
        value: formFieldsData ? formFieldsData?.message : '',
      },
      isCaptchaPassed: false,
    };
  }

  return initialState;
};

export const reducer = (state: InitialState, { type, payload }: Action) => {
  switch (type) {
    case 'set_name_value':
      return {
        ...state,
        name: { value: payload, isValid: payload.length ? true : false },
      };
    case 'set_phone_value':
      return {
        ...state,
        phone: { value: payload, isValid: regexp.test(payload) },
      };
    case 'set_message_value':
      return { ...state, message: { value: payload ? payload : '' } };
    case 'set_captcha_passed':
      return { ...state, isCaptchaPassed: !!payload?.length };
    case 'reset_form':
      return initialState;
    default:
      return state;
  }
};
