import React, { FC, HTMLAttributes } from 'react';
import '../../../../assets/svg/logo.svg';
import '../../../../assets/svg/burger.svg';
import '../../../../assets/svg/close.svg';
import { Link } from 'react-router-dom';
import './Header.scss';
import { NavLinks } from '../NavLinks/NavLinks';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import classNames from 'classnames';
import { useCallbackForm } from '../../../shared/Callback/CallbackFormContext';
import { useYandexMetrika } from '../../../../hooks/useYandexMetrika';

interface HeaderProps extends HTMLAttributes<HTMLHeadElement> {
  isMobileMenuOpen: boolean;
  setMobileMenuClose: () => void;
  setMobileMenuOpen?: () => void;
  area?: 'mobile-menu';
  mix?: 'blue';
}

/**
 * Хедер
 */
export const Header: FC<HeaderProps> = ({
  isMobileMenuOpen,
  setMobileMenuClose,
  setMobileMenuOpen,
  area,
  mix,
  ...restProps
}) => {
  const { setCallbackFormOpen } = useCallbackForm();
  const { reachGoal } = useYandexMetrika();

  return (
    <header {...restProps} className={classNames('header', mix)}>
      <div className="container">
        <nav className="header-grid">
          <Link to={'/'} className="logo icon">
            <svg>
              <use xlinkHref="#logo"></use>
            </svg>
          </Link>
          <div className="social-links">
            <SocialLinks />
          </div>
          <Link
            to={'tel:+78126422574'}
            className={classNames('phone', {
              'in-mobile-menu': area === 'mobile-menu',
            })}
            onClick={() => reachGoal('dailPhone')}
          >
            <h3>+7 (812) 642-25-74</h3>
          </Link>
          <div className="nav-links-wrapper">
            <NavLinks area={'header'} onClose={setMobileMenuClose} />
          </div>
          <div
            className="order-call"
            onClick={() => {
              setCallbackFormOpen(),reachGoal('openCallbackForm');
            }}
          >
            Заказать обратный звонок
          </div>
          <div
            className={classNames('order-call-mobile', {
              'in-mobile-menu': area === 'mobile-menu',
            })}
            onClick={() => {
              setMobileMenuClose(), setCallbackFormOpen();
            }}
          >
            Заказать звонок
          </div>{' '}
          {/* Вариант для мобильной вёрстки */}
          <div
            className="burger"
            onClick={() =>
              isMobileMenuOpen ? setMobileMenuClose() : setMobileMenuOpen()
            }
          >
            <div className="icon">
              <svg>
                <use
                  xlinkHref={`#${isMobileMenuOpen ? 'close' : 'burger'}`}
                ></use>
              </svg>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
