import { Policy } from '../public/Policy/Policy';
import { Calculator } from '../public/Calculator/Calculator';
import { Contacts } from '../public/Contacts/Contacts';
import { Price } from '../public/Price/Price';
import { Service } from '../public/Service/Service';
import { Service1 } from '../public/ServiceItem/Service1/Service1';
import { Service2 } from '../public/ServiceItem/Service2/Service2';
import { News } from '../public/News/News';
import { ReactElement, ReactNode } from 'react';

// TODO: Переделать роуты, как здесь https://stackoverflow.com/questions/75370290/react-typescript-route-file

export type TRoute = {
  path: string;
  title: string;
  description?: string;
  children?: TRoute[];
};

const routes: TRoute[] = [
  {
    path: 'service',
    // element: Service,
    title: 'Услуги',
    description: "Полный спектр бухгалтерских услуг для ООО и ИП: ведение бухгалтерии, расчет налогов, подготовка отчетности, консультации. Работаем дистанционно по всей России. Звоните +7 (812) 642-25-74",
    children: [
      {
        path: 'buhgalterskie-uslugi-dlya-ip',
        // element: Service1,
        title: 'Бухгалтерские услуги для ИП',
        description: "Ведение бухгалтерии для ИП на УСН, ПВДН, ОСНО. Подготовка и сдача отчетности, расчет налогов и взносов, консультации бухгалтера. Узнайте стоимость по телефону: +7 (812) 642-25-74"
      },
      {
        path: 'zapolnenie-deklaracii-3-ndfl',
        // element: Service2,
        title: 'Заполнение декларации 3-НДФЛ',
        description: "Заполним декларацию 3-НДФЛ быстро и грамотно. Вернем налог за лечение, обучение, покупку квартиры. Онлайн и в офисе. Звоните +7 (812) 642-25-74"
      },
    ],
  },
  {
    path: 'price',
    // element: Price,
    title: 'Прайсы',
    description: "Полный спектр бухгалтерских услуг для ООО и ИП. Прозрачные цены на бухгалтерские услуги для ООО и ИП. Ознакомьтесь с нашими тарифами на ведение бухгалтерии, расчет налогов, сдачу отчетности. Звоните +7 (812) 642-25-74"
  },
  {
    path: 'calculator',
    // element: Calculator,
    title: 'Калькулятор бухгалтерских услуг',
    description: "Рассчитайте стоимость бухгалтерских услуг онлайн за 1 минуту! Укажите систему налогообложения, количество сотрудников и операций - получите точный расчет.",
  },
  {
    path: 'news',
    // element: News,
    title: 'Новости',
    description: "Последние новости и изменения в законодательстве для ООО и ИП. Полезные статьи, советы бухгалтера, обзоры нововведений."
  },
  {
    path: 'contacts',
    // element: Contacts,
    title: 'Контакты',
    description: 'Контакты бухгалтерской компании БухЭталонЪ: Санкт-Петербург, Толубеевский проезд, 14, крп. 1, строение 1, телефон: +7 (812) 642-25-74, электронная почта: info@buhetalon.ru, форма обратной связи. Свяжитесь с нами!',
  },
  {
    path: 'policy',
    // element: Policy,
    title: 'Политика конфиденциальности',
    description: 'Политика конфиденциальности ООО "БухЭталонЪ" в отношении персональных данных пользователей сайта. Как мы собираем, используем и защищаем ваши данные.'
  },
];

const getRoutesList = (routesConfig: TRoute[]): Omit<TRoute, 'children'>[] => {
  const routesList: TRoute[] = [];

  const recursion = (arr: TRoute[], str?: string): void => {
    const path = str && !!str.length ? str : '';

    for (const item of arr) {
      const currentPath = path + '/' + item.path;

      routesList.push({ path: currentPath, title: item.title, description: item.description });

      if (item.children) {
        recursion(item.children, currentPath);
      }
    }
  };

  recursion(routesConfig);

  return routesList;
};

export const routesList = getRoutesList(routes);
