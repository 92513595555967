import React from 'react';
import { useRouteData } from '../../../hooks/useRouteData';
import { CallBackForm } from '../../shared/Callback/CallBackForm/CallBackForm';
import { Link } from 'react-router-dom';
import '../../../assets/svg/watch.svg';
import '../../../assets/svg/phone.svg';
import '../../../assets/svg/tg.svg';
import '../../../assets/svg/wa.svg';
import '../../../assets/svg/vb.svg';
import './Contacts.scss';
import { useYandexMetrika } from '../../../hooks/useYandexMetrika';

export const Contacts = () => {
  const { Crumbs, title } = useRouteData();
  const { reachGoal } = useYandexMetrika();

  return (
    <>
      <div className="container">
        <Crumbs />
        <h2>{title}</h2>
      </div>

      <div className="container">
        <div className="contacts-tiles">
          <div className="contacts-tiles__tile">
            <svg>
              <use xlinkHref="#phone"></use>
            </svg>
            <div className="contacts-tiles__tile-info">
              Телефоны
              <br />
              <div className="contacts-tiles__tile-info-grid">
                Офис
                <a href="tel:78126422574" onClick={() => {reachGoal('dailPhone')}}>+7 (812) 642-25-74</a>
                Моб.
                <a href="tel:79643422574" onClick={() => reachGoal('dailPhone')}>+7 (964) 342-25-74</a>
              </div>
            </div>
          </div>

          <div className="contacts-tiles__tile">
            <svg>
              <use xlinkHref="#watch"></use>
            </svg>
            <div className="contacts-tiles__tile-info">
              График работы
              <br />
              <div className="contacts-tiles__tile-info-grid">
                <span>Пн - Пт</span>
                <span>9:00 —19:00</span>
                <span>Сб, Вс</span>
                <span>выходные дни</span>
              </div>
            </div>
          </div>

          <div className="contacts-tiles__tile">
            <div className="contacts-tiles__tile-info">
              Мы в соцсетях
              <br />
              <div className="contacts-tiles__tile-info-flex">
                <a href="https://t.me/buhetalon" onClick={() => reachGoal('goToTelegram')}>
                  <svg>
                    <use xlinkHref="#tg"></use>
                  </svg>
                </a>
                <a href="https://wa.me/79643422574" onClick={() => reachGoal('goToWhatsApp')}>
                  <svg>
                    <use xlinkHref="#wa"></use>
                  </svg>
                </a>
                <a href="https://msng.link/o?79643422574=vi" onClick={() => reachGoal('goToViber')}>
                  <svg>
                    <use xlinkHref="#vb"></use>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contacts-call-back-form">
        <div className="container">
          <div className="contacts-call-back-form__form">
            <div className="contacts-call-back-form__form-content">
              <h3>Заказать обратный звонок</h3>
              <p>
                Любые вопросы вы можете задать по e-mail{' '}
                <a href="mailto:info@buhetalon.ru">info@buhetalon.ru</a> или
                через форму обратной связи
              </p>
              <p>
                Нажимая кнопку, вы даете согласие на{' '}
                <Link to="/policy">обработку персональных данных</Link>
              </p>
            </div>
            <CallBackForm />
          </div>
        </div>
      </div>
    </>
  );
};
