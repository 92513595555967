import React, { HTMLAttributes, FC, useEffect, CSSProperties } from 'react';
import './ModalWrapper.scss';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { CloseButton } from '../CloseButton/CloseButton';

interface ModalWrapperProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  wrapperChildrenStyles?: CSSProperties | undefined;
}

/**
 * Подложка модального окна с возможностью настройки положения модального окна
 */
export const ModalWrapper: FC<ModalWrapperProps> = ({
  children,
  onClose,
  className,
  wrapperChildrenStyles,
  ...restProps
}) => {
  // предотвращаем скролл
  useEffect(() => {
    document.documentElement.classList.add('--prevent-scroll');

    return () => {
      document.documentElement.classList.remove('--prevent-scroll');
    };
  }, []);

  // закрываем по нажатию escape
  useEffect(() => {
    const documentKeyDownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', documentKeyDownListener);

    return () => {
      document.removeEventListener('keydown', documentKeyDownListener);
    };
  }, [onClose]);

  return createPortal(
    <div
      className={classNames(`modal-wrapper`, className)}
      onClick={onClose}
      {...restProps}
    >
      <div
        className="modal-wrapper__children"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        style={wrapperChildrenStyles}
      >
        <CloseButton
          theme="transparent"
          onClose={onClose}
          mixin="absolute-position"
        />
        {children}
      </div>
    </div>,
    document.getElementById('overlay') as HTMLDivElement,
  );
};
