import React from 'react';
import { useRouteData } from '../../../hooks/useRouteData';
import { PriceTable } from './PriceTable/PriceTable';
import { TariffsTable } from './PriceTable/TariffsTable';
import { useProcessedZeroReporting } from '../../../core/query-hooks/useProcessedZeroReporting';
import { useProcessedActivityFeatures } from '../../../core/query-hooks/useProcessedActivityFeatures';
import { useProcessedAdditionalServices } from '../../../core/query-hooks/useProcessedAdditionalServices';
import { useProcessedLegalServices } from '../../../core/query-hooks/useProcessedLegalServices';
import { useProcessedServicesForIndividuals } from '../../../core/query-hooks/useProcessedServicesForIndividuals';
import { useProcessedTariffs } from '../../../core/query-hooks/useProcessedTariffs';
import './Price.scss';

export const Price = () => {
  const { Crumbs, title } = useRouteData();
  const { isZeroReportingLoading, zeroReporting } = useProcessedZeroReporting();
  const { isActivityFeaturesLoading, activityFeatures } =
    useProcessedActivityFeatures();
  const { isAdditionalServicesLoading, additionalServices } =
    useProcessedAdditionalServices();
  const { isLegalServicesLoading, legalServices } = useProcessedLegalServices();
  const { isServicesForIndividualsLoading, servicesForIndividuals } =
    useProcessedServicesForIndividuals();
  const { isTariffsLoadings, tariffs } = useProcessedTariffs();

  return (
    <div className="container">
      <Crumbs />
      <h2>{title}</h2>

      <article className="article">
        <div className="quote">
          Главный принцип бухгалтерского учёта — это регистрация абсолютно
          каждого события деятельности организации в регистрах бухгалтерского
          учета и на специальных счетах (составление так называемых
          бухгалтерских проводок). Каждая такая операция должна быть
          подтверждена документом (банковская выписка, УПД, товарная накладная,
          счёт/фактура, акт выполненных работ, авансовый отчёт, путевой лист и
          т.д.).
        </div>

        <h5>
          В настоящем прайс-листе под «операцией» понимается отражение в
          программе 1С одного события из следующих:
        </h5>
        <ul>
          <li>
            Операция по проведению одной банковской проводки (кроме платежей
            в бюджет и комиссии банка)
          </li>
          <li>Операция по поступлению товаров/услуг (комплект документов)</li>
          <li>Операция по реализации товаров/услуг (комплект документов)</li>
          <li>Составление путевого лист</li>
          <li>Регистрация одного документа в авансовом отчёте</li>
        </ul>
        <p>
          В зависимости от деятельности организации возможны иные виды операций
          и первичных документов.
        </p>

        <PriceTable
          data={zeroReporting}
          isLoading={isZeroReportingLoading}
          title="«Нулевая» отчетность для ООО и ИП"
          headers={['Наименование услуги', 'УСН (Д и Д-Р)', 'ОСНО']}
          footnote={() => (
            <p>
              * Наличие и условия системы для сдачи отчетности в электронном
              виде по ТКС оговариваются отдельно и зависят от провайдера, с
              которым у Клиента заключен договор. ООО «БухЭталонЪ» не берёт
              дополнительную плату к тарификации, цены и условия устанавливает
              провайдер.
            </p>
          )}
        />

        <TariffsTable
          data={tariffs}
          isLoading={isTariffsLoadings}
          pretext={[
            'Прайс ежемесячного обслуживания зависит от юридической формы:',
          ]}
          title="Стоимость ежемесячного бухгалтерского обслуживания"
          headers={[
            'Количество операций',
            'Общая система (ОСНО)',
            'УСН (Доходы - Расходы)',
            'УСН (Доходы 6%)',
          ]}
        />

        <PriceTable
          data={activityFeatures}
          isLoading={isActivityFeaturesLoading}
          title="Надбавки к тарифам в зависимости от особенности деятельности"
          headers={['Наименование услуги', 'Надбавка, %']}
        />

        <PriceTable
          data={additionalServices}
          isLoading={isAdditionalServicesLoading}
          title="Дополнительные услуги, не входящие в тарифы"
          pretext={[
            'Дополнительные услуги оказываются дополнительно к тарифу или как отдельная услуга.',
          ]}
          headers={['Наименование услуги', 'Цена, руб']}
          footnote={() => (
            <>
              <p>
                ** один договор/контракт до 5 страниц, свыше - 100р. за каждую
                страницу
              </p>
              <p>
                *** округление производится до 20 минут, каждые 20 полных и
                неполных минут 500р.
              </p>
            </>
          )}
        />

        <PriceTable
          data={legalServices}
          isLoading={isLegalServicesLoading}
          title="Стоимость юридических услуг"
          pretext={[
            'При регистрации бизнеса необходимо учесть массу нюансов, одновременно занимаясь запуском дела. Начинающему предпринимателю приходится встречаться с потенциальными деловыми партнёрами и будущими сотрудниками, подыскивать офисные помещения, оценивать перспективы тех или иных предложений. Поэтому, зачастую у предпринимателей нет времени заниматься самой регистрацией. Оптимальным решением будет обращение к нам за помощью.',
          ]}
          headers={['Наименование услуги', 'Цена, руб']}
          footnote={() => (
            <p>
              **** При заключении договора на бухгалтерское обслуживание, услуга
              предоставляется бесплатно
            </p>
          )}
        />

        <PriceTable
          data={servicesForIndividuals}
          isLoading={isServicesForIndividualsLoading}
          title="Услуги физическим лицам"
          headers={['Наименование услуги', 'Цена, руб']}
        />
      </article>
    </div>
  );
};
