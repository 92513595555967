import {
  ACTIVITY_FEATURES,
  ADDITIONAL_SERVICES,
  EMPLOYEES_SETTINGS,
  LEGAL_FORM,
  LEGAL_SERVICES,
  MORE_ABOUT_TARIFFS,
  NEWS,
  NUMBER_OF_OPERATIONS,
  SERVICES_FOR_INDIVIDUALS,
  TARIFF,
  TARIFF_PLAN,
  TAXATION_SYSTEM,
  URL,
  ZERO_REPORTING,
} from './constants';
import {
  TZeroReporting,
  TZeroReportingApiFormat,
  TActivityFeatures,
  TActivityFeaturesApiFormat,
  TAdditionalServices,
  TAdditionalServicesApiFormat,
  TLegalServices,
  TLegalServicesApiFormat,
  TServicesForIndividuals,
  TServicesForIndividualsApiFormat,
  TLegalForm,
  TLegalFormApiFormat,
  TTaxationSystem,
  TTaxationSystemApiFormat,
  TTariffPlan,
  TTariffPlanApiFormat,
  TEmployeesSettings,
  TEmployeesSettingsApiFormat,
  TNumberOfOperations,
  TNumberOfOperationsApiFormat,
  TTariff,
  TDataApiFormat,
  TMoreAboutTariffs,
  TMoreAboutTariffsApiFormat,
  TNewsApiFormat,
  TNews,
} from './types';

/* ------------- Нулевые отчёты ------------- */
export const getZeroReporting = async () => {
  const response = await fetch(URL + ZERO_REPORTING);
  const parsedResponse = (await response.json()) as TZeroReportingApiFormat;
  const data: TZeroReporting[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TZeroReporting = {
      title: item.fields.title.stringValue,
      USN: item.fields.USN.stringValue,
      OSNO: item.fields.OSNO.stringValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Особенности деятельности ------------- */
export const getActivityFeatures = async () => {
  const response = await fetch(URL + ACTIVITY_FEATURES);
  const parsedResponse = (await response.json()) as TActivityFeaturesApiFormat;
  const data: TActivityFeatures[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TActivityFeatures = {
      title: item.fields.title.stringValue,
      shortTitle: item.fields.shortTitle.stringValue,
      percent: item.fields.percent.integerValue,
      popup: item.fields.popup.stringValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Дополнительные услуги ------------- */
export const getAdditionalServices = async () => {
  const response = await fetch(URL + ADDITIONAL_SERVICES);
  const parsedResponse =
    (await response.json()) as TAdditionalServicesApiFormat;
  const data: TAdditionalServices[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TAdditionalServices = {
      title: item.fields.title.stringValue,
      price: item.fields.price.stringValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Юридические услуги ------------- */
export const getLegalServices = async () => {
  const response = await fetch(URL + LEGAL_SERVICES);
  const parsedResponse = (await response.json()) as TLegalServicesApiFormat;
  const data: TLegalServices[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TLegalServices = {
      title: item.fields.title.stringValue,
      price: item.fields.price.stringValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Услуги физическим лицам ------------- */
export const getServicesForIndividuals = async () => {
  const response = await fetch(URL + SERVICES_FOR_INDIVIDUALS);
  const parsedResponse =
    (await response.json()) as TServicesForIndividualsApiFormat;
  const data: TServicesForIndividuals[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TServicesForIndividuals = {
      title: item.fields.title.stringValue,
      price: item.fields.price.stringValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Правовая форма ------------- */
export const getLegalForm = async () => {
  const response = await fetch(URL + LEGAL_FORM);
  const parsedResponse = (await response.json()) as TLegalFormApiFormat;
  const data: TLegalForm[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TLegalForm = {
      title: item.fields.title.stringValue,
      isShown: item.fields.isShown.booleanValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Система налогообложения ------------- */
export const getTaxationSystem = async () => {
  const response = await fetch(URL + TAXATION_SYSTEM);
  const parsedResponse = (await response.json()) as TTaxationSystemApiFormat;
  const data: TTaxationSystem[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TTaxationSystem = {
      title: item.fields.title.stringValue,
      shortTitle: item.fields.shortTitle.stringValue,
      isShown: item.fields.isShown.booleanValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Наименование тарифов ------------- */
export const getTariffPlan = async () => {
  const response = await fetch(URL + TARIFF_PLAN);
  const parsedResponse = (await response.json()) as TTariffPlanApiFormat;
  const data: TTariffPlan[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TTariffPlan = {
      title: item.fields.title.stringValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Настройки сотрудников ------------- */
export const getEmployeesSettings = async () => {
  const response = await fetch(URL + EMPLOYEES_SETTINGS);
  const parsedResponse = (await response.json()) as TEmployeesSettingsApiFormat;
  const data: TEmployeesSettings[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TEmployeesSettings = {
      id: item.name.split('/').reverse()[0],
      settings: {
        min: {
          title:
            item.fields.settings.mapValue.fields.min.mapValue.fields.title
              .stringValue,
          value:
            item.fields.settings.mapValue.fields.min.mapValue.fields.value
              .stringValue,
        },
        max: {
          title:
            item.fields.settings.mapValue.fields.max.mapValue.fields.title
              .stringValue,
          value:
            item.fields.settings.mapValue.fields.max.mapValue.fields.value
              .stringValue,
        },
        price: {
          title:
            item.fields.settings.mapValue.fields.price.mapValue.fields.title
              .stringValue,
          value:
            item.fields.settings.mapValue.fields.price.mapValue.fields.value
              .stringValue,
        },
      },
    };

    data.push(obj);
  });

  return data[0];
};

/* ------------- Количество операций ------------- */
export const getNumberOfOperations = async () => {
  const response = await fetch(URL + NUMBER_OF_OPERATIONS);
  const parsedResponse =
    (await response.json()) as TNumberOfOperationsApiFormat;
  const data: TNumberOfOperations[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TNumberOfOperations = {
      title: item.fields.title.stringValue,
      min: +item.fields.min.integerValue,
      max: +item.fields.max.integerValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Тарифы ------------- */
export const getTariffs = async () => {
  const response = await fetch(URL + TARIFF);
  const parsedResponse = (await response.json()) as TDataApiFormat;
  const data: { tariff: TTariff[] } = {
    tariff: parsedResponse.documents[0].fields.tariff.arrayValue.values.map(
      (value) => {
        return {
          data: value.mapValue.fields.data.arrayValue.values.map((val) => {
            return {
              data_1: val.mapValue.fields.data_1.arrayValue.values.map((v) => {
                return {
                  taxationSystemId:
                    v.mapValue.fields.taxationSystemId.stringValue,
                  title: v.mapValue.fields.title.stringValue,
                  value: +v.mapValue.fields.value.integerValue,
                  sorting: +v.mapValue.fields.sorting.integerValue,
                };
              }),
              legalFormId: val.mapValue.fields.legalFormId.stringValue,
              numberOfOperationsId:
                val.mapValue.fields.numberOfOperationsId.stringValue,
              tariffPlanId: val.mapValue.fields.tariffPlanId.stringValue,
              title: val.mapValue.fields.title.stringValue,
              sorting: +val.mapValue.fields.sorting.integerValue,
            };
          }),
          tariffPlanId: value.mapValue.fields.tariffPlanId.stringValue,
          legalFormId: value.mapValue.fields.legalFormId.stringValue,
        };
      },
    ),
  };

  return data;
};

/* ------------- Подробнее о тарифах ------------- */
export const getMoreAboutTariffs = async () => {
  const response = await fetch(URL + MORE_ABOUT_TARIFFS);
  const parsedResponse = (await response.json()) as TMoreAboutTariffsApiFormat;
  const data: TMoreAboutTariffs[] = [];

  parsedResponse.documents.forEach((item) => {
    const obj: TMoreAboutTariffs = {
      title: item.fields.title.stringValue,
      tariffs: item.fields.tariffs.arrayValue.values.map((tariff) => ({
        tariffPlanId: tariff.mapValue.fields.tariffPlanId.stringValue,
        tariffPlanTitle: tariff.mapValue.fields.tariffPlanTitle.stringValue,
        contained: tariff.mapValue.fields.contained.booleanValue,
      })),
      additionalService: item.fields.additionalService.stringValue,
      unit: item.fields.unit.stringValue,
      sorting: +item.fields.sorting.integerValue,
      id: item.name.split('/').reverse()[0],
    };

    data.push(obj);
  });

  data.sort((a, b) => a.sorting - b.sorting);

  return data;
};

/* ------------- Новости ------------- */
export const getNews = async () => {
  try {
    const response = await fetch(URL + NEWS);
    const parsedResponse = (await response.json()) as TNewsApiFormat;
    const data: TNews[] = [];
    // console.log(parsedResponse)
    if(parsedResponse.documents) {
      parsedResponse.documents.forEach((item) => {
        const obj: TNews = {
          title: item.fields.title.stringValue,
          link: item.fields.link.stringValue,
          date: item.fields.date.stringValue,
          announce: item.fields.announce.stringValue,
          editorValue: item.fields.editorValue.stringValue,
          image: item.fields.image.mapValue ? {
            title: item.fields.image.mapValue.fields.title.stringValue,
            alt: item.fields.image.mapValue.fields.alt.stringValue,
            src: item.fields.image.mapValue.fields.src.stringValue,
            id: item.fields.image.mapValue.fields.id.stringValue,
          } : null,
          id: item.name.split('/').reverse()[0],
        };
  
        data.push(obj);
      });
  
      data.sort((a, b) => +a.date - +b.date);
    }

    return data;
  } catch(e) {
    console.log(e)
    Promise.reject(e)
  }
}

/* ------------- Единичная новость ------------- */
export const getNewsItem = async (id: string): Promise<TNews> => {
  try {
    const response = await fetch(URL + NEWS + '/' + id);
    const parsedResponse = await response.json();

    if(parsedResponse.error?.code === 404) {
      throw Error('Ошибка 404') 
    }

    const obj: TNews = {
      title: parsedResponse.fields.title.stringValue,
      link: parsedResponse.fields.link.stringValue,
      date: parsedResponse.fields.date.stringValue,
      announce: parsedResponse.fields.announce.stringValue,
      editorValue: parsedResponse.fields.editorValue.stringValue,
      image: parsedResponse.fields.image.mapValue ? {
        title: parsedResponse.fields.image.mapValue.fields.title.stringValue,
        alt: parsedResponse.fields.image.mapValue.fields.alt.stringValue,
        src: parsedResponse.fields.image.mapValue.fields.src.stringValue,
        id: parsedResponse.fields.image.mapValue.fields.id.stringValue,
      } : null,
      id: parsedResponse.name?.split('/').reverse()[0],
    };
// console.log(parsedResponse)
    return obj;
  } catch(e) {
    console.log(e)
  }
}