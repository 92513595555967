import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { ModalWrapper } from '../../ModalWrapper/ModalWrapper';
import { useCallbackForm } from '../CallbackFormContext';
import { CallBackForm } from '../CallBackForm/CallBackForm';
import { Link } from 'react-router-dom';
import './CallBackModal.scss';

/**
 * Модальное окно обратного звонка
 */
export const CallBackModal = () => {
  const { isCallbackForm, setCallbackFormClose } = useCallbackForm();

  return (
    <CSSTransition
      in={isCallbackForm}
      timeout={300}
      mountOnEnter
      unmountOnExit
      classNames="modal"
    >
      <ModalWrapper onClose={setCallbackFormClose}>
        <div className="callback-form">
          <h3>Заказать обратный звонок</h3>
          <p className="callback-form__description">
            Оставьте номер вашего телефона, наши специалисты перезвонят в
            рабочее время <span>(ПН - ПТ с 9:00 до 19:00)</span> в течение
            нескольких минут
          </p>
          <CallBackForm />
          <p className="callback-form__disclaimer">
            Нажимая кнопку, вы даете согласие на{' '}
            <Link to="/policy" onClick={setCallbackFormClose}>
              обработку персональных данных
            </Link>
          </p>
        </div>
      </ModalWrapper>
    </CSSTransition>
  );
};
