import React from 'react';
import { useRouteData } from '../../../../hooks/useRouteData';

export const Service1 = () => {
  const { Crumbs, title } = useRouteData();

  return (
    <>
      <div className="container">
        <Crumbs />
        <h2>{title}</h2>
      </div>

      <div className="container">
        <article className="article">
          <h5>
            Вопрос - обязан ли ИП вести бухучет? (Если этот вопрос вас не
            интересует, то смело переходите к вопросу №2)
          </h5>
          <p>
            Чтож, мы понимаем, что времни у бизнесменов мало, поэтому вкратце.
          </p>
          <p>
            Ответ на этот вопрос нам даёт ст. 2 закона «О бухгалтерском учете»
            от 06.12.2011 № 402-ФЗ. К обязанным вести бухучет в РФ относятся:
          </p>
          <ul>
            <li>
              организации, занимающиеся коммерческой и некоммерческой
              деятельностью;
            </li>
            <li>
              госорганы, органы местного самоуправления, органы управления
              внебюджетных госфондов (в т. ч. территориальных);
            </li>
            <li>ИП, частнопрактикующие лица;</li>
            <li>
              находящиеся в РФ представительства и подразделения иностранных и
              международных организаций.
            </li>
          </ul>
          <p>
            Получается, формально ИП обязанны вести бухгалтерский учет. Но...
          </p>
          <p>
            В п. 2 ст. 6 закона № 402-ФЗ ИП упоминаются среди тех, кто может
            этого не делать. При этом ИП должны вести учет доходов и расходов и
            других связанных с его деятельностью объектов налогообложения и
            физических показателей.
          </p>
          <p>
            Вы наверое спросите, а что же понимается под ведением учета,
            упомянутого в п. 2 ст. 6 закона № 402-ФЗ? Ответ простой - это
            налоговый учёт, т.е. способ ведения учёта, который позволяет ИП
            правильно рассчитать налог.
          </p>
          <p>
            Итог такой: если мы берем ИП на УСН, ОСНО или на патенте - то все
            они обязаны вести налоговый учет, т.е. вести КУДИР (книга учета
            доходов и расходов), а значит если НУ обязателен, то БУ можно не
            вести.
          </p>

          <p>
            Итак мы разобрались, что ИП обязан вести НУ (налоговый учет), дабы в
            случае проверки налоговики убедились что налог вы посчитали верно.
            Но ведь это не так просто, если у ИП на патенте более простой учет
            (хотя есть свои нюансы - подробнее в статье ***************), то ИП
            на ОСНО и тем более на УСН - это уже сложный налоговый учет. Вот
            здесь ИП и понадобятся наши услуги ;-)
          </p>
          <p>
            Мы предлагаем Предпринимателям простой и ненавязчивый учёт. Самое
            главное - посчитать правильно налог и чтобы все документы были в
            наличии (имеются ввиду документы поступления - так как это расход,
            если вы на ОСНО или УСН).
          </p>
          <p>Мы можем:</p>
          <ul>
            <li>взять весь учёт от А до Я на себя - с вас долько документы;</li>
            <li>снизить ваши налоги не привлекая внимания</li>
          </ul>
          <p>Мы подскажем:</p>
          <ul>
            <li>
              как проще всего открыть ИП - на самом деле это просто, большинство
              банков бесплатно предоставляют эту услугу, но опять таки нужно
              проконтролировать, чтобы банк при регистрации подал за вас
              уведомление о переходе на выбранную систему налогообложения, а
              затем запросить у ФНС подтверждение;
            </li>
            <li>
              в каком банке удобнее открыться, тарифы на снятие наличных и
              примерную комиссию банка;
            </li>
            <li>
              какую систему налогообложения выбрать, чтобы сэкономить на налогах
              -УСН (доходы), УСН (доходы минус расходы), ОСНО или патент;
            </li>
            <li>
              поможем с оформлением ключа электронной подписи (ЭЦП) - он нужен
              для сдачи отчетов в ФНС
            </li>
          </ul>
          <h5>Вполне резонный вопрос - почему мы?</h5>
          <ul>
            <li>
              за вами будет закреплен один профессионал, который и будет всегда
              работать с вами, но если что, у нас всегда есть человек &quot;на
              подхвате&quot; на непредвиденные случаи;
            </li>
            <li>мы на связи в удобном для вас мессенджере;</li>
            <li>в нашу команду идет строгий отбор;</li>
            <li>
              мы точно знаем, что качество - это путь к успеху, в том числе
              финансовому, поэтому наши специалисты работают на более выгодных
              условиях, чем в аналогичных компаниях, но при этом и спрос за
              качество серьёзнее. Такая схема - даёт отличный результат!
            </li>
            <li>наши услуги на порядок выгоднее штатного бухгалтера;</li>
            <li>мы дорожим своими клиентами!</li>
          </ul>
          <p>
            Если вы все еще сомневаетесь, то просто позвоните нам! Мы
            проконсультируем вас и рассчитаем стоимость обслуживания, а дальше
            дело за вами!
          </p>
        </article>
      </div>
    </>
  );
};
