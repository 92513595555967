import React, { FormEvent, useEffect, useReducer, useState } from 'react';
import { Button } from '../../Button/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import { reducer, createInitialState } from './reducer';
import classNames from 'classnames';
import { withSpinner } from '../../../../hocs/withSpinner';
import { useCallbackForm } from '../CallbackFormContext';
import './CallBackForm.scss';
import { useYandexMetrika } from '../../../../hooks/useYandexMetrika';

const ElementWithSpinner = withSpinner(({ children }) => <>{children}</>);

const delay = (ms: number): Promise<any> =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Отрисует форму обратного звонка
 */
export const CallBackForm = () => {
  const { reachGoal } = useYandexMetrika();
  const {
    formFieldsData,
    updateFormFieldsData,
    isLoading,
    setLoadingTrue,
    setLoadingFalse,
    isThankYouForm,
    setCallbackFormClose,
    setThankYouFormOpen,
  } = useCallbackForm();
  const [formState, formDispatch] = useReducer(
    reducer,
    createInitialState(formFieldsData),
  ); // стейт данных формы
  const [areWarningsShown, setWarningsShown] = useState(false); // флаг показа не заполненных полей
  const [isButtonEnabled, setButtonEnabled] = useState(false); // флаг активного состояния кнопки

  useEffect(() => {
    if (formState.isCaptchaPassed) {
      setWarningsShown(true);
    }

    if (
      formState.name.isValid &&
      formState.phone.isValid &&
      formState.isCaptchaPassed
    ) {
      setButtonEnabled(true);
      setWarningsShown(false);
    } else {
      setButtonEnabled(false);
    }
  }, [formState]);

  const formHandler = (e: FormEvent) => {
    e.preventDefault();

    const data: typeof formFieldsData = {
      name: formState.name.value,
      phone: formState.phone.value,
      message: formState.message.value,
    };

    if (!!data.name.length && !!data.phone.length) {
      const message = `Пользователь ${data.name} с телефоном: ${'+' + data.phone.toString()} оставил сообщение:\n
      "${data.message}"`
      setLoadingTrue();

      delay(1000)
        .then(() => fetch(`/send-email?message=${message}`))
        .then((response) => response.text())
        .then((text) => {
          console.log(text),
          setLoadingFalse(),
          setCallbackFormClose(),
          setThankYouFormOpen()
        })
        .catch((e) => {
          console.log('FAILED...', e.message),
          setLoadingFalse(),
          setCallbackFormClose()
        });
    }
    updateFormFieldsData(data);
    setButtonEnabled(false);
    reachGoal('sendCallbackForm');
  };

  useEffect(() => {
    if (isThankYouForm) {
      formDispatch({ type: 'reset_form' });
    }
  }, [isThankYouForm]);

  return (
    <form className="callback-form__form" onSubmit={formHandler}>
      <div className="callback-form__form-input">
        <input
          type="text"
          role="name"
          className={classNames('callback-form__form-name', {
            invalid: areWarningsShown && !formState.name.isValid,
          })}
          placeholder="имя *"
          autoFocus
          value={formState.name.value}
          onChange={(e) =>
            formDispatch({ type: 'set_name_value', payload: e.target.value })
          }
        />
        {areWarningsShown && !formState.name.isValid && (
          <div className="callback-form__form-input-alert">
            Поле обязательно для заполнения
          </div>
        )}
      </div>
      <div className="callback-form__form-input">
        <input
          type="text"
          role="phone"
          className={classNames('callback-form__form-phone', {
            invalid: areWarningsShown && !formState.phone.isValid,
          })}
          placeholder="телефон *"
          value={formState.phone.value}
          onChange={(e) =>
            formDispatch({ type: 'set_phone_value', payload: e.target.value })
          }
        />
        {areWarningsShown && !formState.phone.isValid && (
          <div className="callback-form__form-input-alert">
            {formState.phone.value.length
              ? 'Нужен префикс +7 или 8 и далее 10 цифр'
              : 'Поле обязательно для заполнения'}
          </div>
        )}
      </div>
      <textarea
        role="message"
        className="callback-form__form-message"
        placeholder="сообщение"
        value={formState.message.value}
        onChange={(e) =>
          formDispatch({ type: 'set_message_value', payload: e.target.value })
        }
      />
      <div className="callback-form__form-captcha g-recaptcha">
        <ReCAPTCHA
          sitekey="6LchUHIbAAAAAANox963b79cKpflkTsxVMiF7Cpu"
          onChange={(value) =>
            formDispatch({ type: 'set_captcha_passed', payload: value })
          }
        />
      </div>
      <Button theme="beige" mixin={!isButtonEnabled ? 'disabled' : undefined}>
        {isLoading ? (
          <ElementWithSpinner>Перезвоните&nbsp;мне</ElementWithSpinner>
        ) : (
          <>Перезвоните&nbsp;мне</>
        )}
      </Button>
    </form>
  );
};
