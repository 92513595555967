import React from 'react';
import { Link } from 'react-router-dom';
import { useRouteData } from '../../../hooks/useRouteData';
import { useNews } from '../../../core/query-hooks/useNews';
import { NewsImage } from '../../shared/Image/NewsImage';
import { formatDate } from '../../../utils/formatDate';
import './News.scss';

export const News = () => {
  const { Crumbs, title } = useRouteData();
  const { isNewsLoading, news } = useNews();

  if(isNewsLoading) {
    return (
      <div className="container">
        <Crumbs />
        <h2>{title}</h2>

        Загрузка...
      </div>
    )
  }

  if(!news?.length) {
    return (
      <div className="container">
        <Crumbs />
        <h2>{title}</h2>

        Новостей ещё нет
      </div>
    )
  }
console.log(isNewsLoading, news)
  return (
    <div className="container">
      <Crumbs />
      <h2>{title}</h2>

      <article className='article'>
      <div className='news-grid'>
          {news.map(({ title, link, date, src, alt, id }) => (
            <Link to={`/news/${link}`} className='news-grid__item' key={id}>
              <NewsImage style={{ marginBottom: '18px' }} src={src} alt={alt} />
              <div className='news-grid__item-title'>{title}</div>
              <div className='news-grid__item-date'>{formatDate(date)}</div>
            </Link>
          ))}
        </div>
      </article>
    </div>
  );
};
