import React from 'react';
import './Footer.scss';
import { NavLinks } from '../NavLinks/NavLinks';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import '../../../../assets/svg/footer-logo.svg';
import '../../../../assets/svg/phone.svg';
import { Link } from 'react-router-dom';
import { useYandexMetrika } from '../../../../hooks/useYandexMetrika';

/**`
 * Футер
 */
export const Footer = () => {
  const { reachGoal } = useYandexMetrika();

  return (
    <footer className="footer">
      <div className="container">
        <NavLinks area={'footer'} />

        <div className="footer__info">
          <div className="footer__info-logo icon">
            <svg>
              <use xlinkHref="#footer-logo"></use>
            </svg>
          </div>
          <div className="footer__info-promo">
            <h3>Профессиональные бухгалтерские услуги</h3>
            работаем по всей России
          </div>
          <div className="footer__info-contacts">
            <div className="footer__info-contacts-phone">
              <div className="icon">
                <svg>
                  <use xlinkHref="#phone"></use>
                </svg>
              </div>
              <Link to="tel:+78126422574" onClick={() => reachGoal('dailPhone')}>
                <h3>+7 (812) 642-25-74</h3>
              </Link>
            </div>
            <Link to="mailto:info@buhetalon.ru" onClick={() => reachGoal('openEmail')}>info@buhetalon.ru</Link>
          </div>
          <div className="footer__info-disclaimer">
            <p>
              © 2020-2023 Официальный сайт компании «БухЭталонЪ»<br></br>
              Любое копирование информации разрешено только со ссылкой на данный
              ресурс
            </p>
            <p>
              Разработка и продвижение сайта{' '}
              <Link to="https://ogiva.ru">ogiva.ru</Link>
            </p>
          </div>
          <SocialLinks />
        </div>
      </div>
    </footer>
  );
};
