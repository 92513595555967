import React from 'react';
import { CallBackForm } from './CallBackForm/CallBackForm';
import { ThankYouForm } from './ThankYouForm/ThankYouForm';
import { CallBackModal } from './CallBackModal/CallBackModal';

/**
 * Набор форм обратного звонка
 */
export const CallbackFormsSet = () => (
  <>
    <CallBackModal />
    <ThankYouForm />
  </>
);
