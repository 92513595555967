import React, { useCallback } from 'react';
import classNames from 'classnames';
import './SquareButton.scss';

interface SquareButtonButtonProps {
  arrow: 'left' | 'right' | 'up';
  theme: 'white' | 'bordered' | 'transparent';
  mixin?: 'active' | 'up-button' | 'arrow-button';
  onclick?: () => void;
}

/**
 * Квадратная кнопка навигации.
 *  @property {'left' | 'right' | 'up'} arrow - вид стрелки
 *  @property {'white' | 'bordered' | 'transparent'} theme - тема оформления
 *  @property {string} [mixin]
 *  @property {string} [onclick]
 */
export const SquareButton = ({
  arrow,
  theme,
  mixin,
  onclick,
}: SquareButtonButtonProps) => {
  const themeClass = `${theme}-theme`;

  const Arrow = useCallback(() => {
    if (arrow === 'left') {
      return (
        <path className="arrow" d="M34.5 21L25 30.5L34.5 40" strokeWidth="2" />
      );
    }

    if (arrow === 'right') {
      return (
        <path
          className="arrow"
          d="m24.293 21 9.5 9.5-9.5 9.5"
          strokeWidth="2"
        />
      );
    }

    return (
      <>
        <rect className="background" x="1" y="1" width="58" height="58" />
        <path
          className="arrow"
          d="M20 31L30.5 20.5M30.5 20.5L41 31M30.5 20.5V39.5"
          strokeWidth="2"
        />
      </>
    );
  }, [arrow]);

  return (
    <button
      className={classNames('square-button', themeClass, mixin)}
      onClick={onclick}
    >
      <svg
        fill="none"
        version="1.1"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect className="background" x="1" y="1" width="58" height="58" />
        <Arrow />
        <rect
          className="border"
          x="1"
          y="1"
          width="58"
          height="58"
          strokeWidth="2"
        />
      </svg>
    </button>
  );
};
