import { useQuery } from 'react-query';
import { getLegalServices } from '../restAPI';
import { TLegalServices, TRow } from '../types';

const staleTime = 60 * 60 * 1000;

const processData = (data: TLegalServices[]): Promise<TRow[]> =>
  new Promise((resolve, reject) => {
    try {
      const dataToArr: TRow[] = [];

      data.map(({ title, price, id }) => {
        dataToArr.push({ id, row: [title, `${price} руб`] });
      });

      resolve(dataToArr);
    } catch (e) {
      reject(e);
    }
  });

export const useProcessedLegalServices = () => {
  const { data: initialData } = useQuery({
    queryKey: ['legal-services'],
    queryFn: getLegalServices,
    placeholderData: [],
    staleTime,
  });

  const { data: processedData } = useQuery({
    queryKey: ['processed-legal-services'],
    queryFn: () => processData(initialData),
    placeholderData: [],
    staleTime,
    enabled: !!initialData.length,
  });

  return {
    isLegalServicesLoading: !processedData.length,
    legalServices: processedData,
  };
};
