import tekHovered from '../../../../assets/images/tek-hover.png';
import tekDefault from '../../../../assets/images/tek-default.png';
import tek1Hovered from '../../../../assets/images/tek-1-hover.png';
import tek1Default from '../../../../assets/images/tek-1-default.png';
import galaxyHovered from '../../../../assets/images/galaxy-hover.png';
import galaxyDefault from '../../../../assets/images/galaxy-default.png';
import sulakHovered from '../../../../assets/images/sulak-hover.png';
import sulakDefault from '../../../../assets/images/sulak-default.png';
import romaHovered from '../../../../assets/images/roma-hover.png';
import romaDefault from '../../../../assets/images/roma-default.png';
import pushkinTauHovered from '../../../../assets/images/pushkin-tau-hover.png';
import pushkinTauDefault from '../../../../assets/images/pushkin-tau-default.png';
import helpQuickHovered from '../../../../assets/images/help-quick-hover.png';
import helpQuickDefault from '../../../../assets/images/help-quick-default.png';

type Collection = {
  [index: string]: {
    hovered: string;
    default: string;
  };
};

const collection: Collection = {
  tek: {
    default: tekDefault,
    hovered: tekHovered,
  },
  'tek-1': {
    default: tek1Default,
    hovered: tek1Hovered,
  },
  galaxy: {
    default: galaxyDefault,
    hovered: galaxyHovered,
  },
  sulak: {
    default: sulakDefault,
    hovered: sulakHovered,
  },
  roma: {
    default: romaDefault,
    hovered: romaHovered,
  },
  'pushkin-tau': {
    default: pushkinTauDefault,
    hovered: pushkinTauHovered,
  },
  'help-quick': {
    default: helpQuickDefault,
    hovered: helpQuickHovered,
  },
};

export const getImage = (
  image: string,
): { hovered: string; default: string } | undefined => {
  const k = Object.keys(collection).find((key) => key === image);

  if (k) {
    return collection[k];
  }
};
