import React, { CSSProperties } from 'react';

export const NoSign = () => {
  const style: CSSProperties = {
    display: 'block',
  };

  return (
    <svg
      style={style}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#D54545" />
      <rect x="6" y="9.99976" width="10" height="2" fill="white" />
    </svg>
  );
};
