import { useQuery } from 'react-query';
import { getNewsItem } from '../restAPI';

const staleTime = 60 * 60 * 1000;

export const useNewsItem = (link: string) => {
  const { data, isError, isFetching } = useQuery({
    queryKey: ['news-item', link],
    queryFn: () => getNewsItem(link),
    placeholderData: null,
    staleTime,
  });

  return {
    isNewsLoading: isFetching,
    newsItem: !isError ? data : null,
  };
};
