import React, {
  useState,
  useRef,
  useEffect,
  PropsWithChildren,
  SyntheticEvent,
} from 'react';
import { NavLink } from 'react-router-dom';
import { withArrowDown } from '../../../hocs/withArrowDown';
import { CSSTransition } from 'react-transition-group';
import './MenuItemCollapsible.scss';

const ElementWithArrowDown = withArrowDown(({ children }) => <>{children}</>);

/**
 * Открывающийся пункт меню
 */
export const MenuItemCollapsible = ({ children }: PropsWithChildren) => {
  const [isOpened, setOpened] = useState(false);
  const ref = useRef<HTMLAnchorElement | null>(null);

  const windowClickHandler = (e: MouseEvent) => {
    if (e.target !== ref.current) {
      setOpened(false);
    }
  };

  const navLinkClickHandler = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault(), e.stopPropagation(), setOpened((prev) => !prev);
  };

  useEffect(() => {
    addEventListener('click', windowClickHandler);

    return () => removeEventListener('click', windowClickHandler);
  }, []);

  return (
    <NavLink to="/service" onClick={(e) => navLinkClickHandler(e)} ref={ref}>
      <ElementWithArrowDown isRotated={isOpened}>Услуги</ElementWithArrowDown>
      <CSSTransition
        in={isOpened}
        timeout={300}
        classNames={'submenu'}
        mountOnEnter
        unmountOnExit
        appear
      >
        {children}
      </CSSTransition>
    </NavLink>
  );
};
