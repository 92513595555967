import React, {
  CSSProperties,
  ComponentType,
  HTMLAttributes,
  ReactNode,
} from 'react';
import '../assets/svg/bullet.svg';

interface WithBulletProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode | string | undefined | false;
  style?: CSSProperties;
}

export function withBullet<T extends WithBulletProps>(
  Component: ComponentType<T>,
) {
  // eslint-disable-next-line react/display-name
  return (props: WithBulletProps & T) => {
    const { children, style } = props;

    const divStyle = {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
      gap: '15px',
    };

    const divIconStyle = {
      width: '11px',
      height: '10px',
    };

    return (
      <Component {...props}>
        <div style={{ ...divStyle, ...style }}>
          <div className="icon" style={{ ...divIconStyle }}>
            <svg>
              <use xlinkHref="#bullet"></use>
            </svg>
          </div>
          {children}
        </div>
      </Component>
    );
  };
}
