import React from 'react';
import { useRouteData } from '../../../../hooks/useRouteData';

export const Service2 = () => {
  const { Crumbs, title } = useRouteData();

  return (
    <>
      <div className="container">
        <Crumbs />
        <h2>{title}</h2>
      </div>

      <div className="container">
        <article className="article">
          <p>
            Заполнение декларация 3-НДФЛ требуется тем, кто хочет получить
            вычет, отчитаться о полученных доходах, рассчитать и заплатить налог
            с них.
          </p>
          <p>
            Каждый сотрудник, который работает по трудовому договору, платит
            НДФЛ в размере 13% от своей зарплаты. Налоговый вычет поможет вам
            вернуть эту сумму. Оформить возврат можно, например, за расходы по
            лечению, обучению или покупке недвижимости. Для этого нужно
            правильно заполнить и подать декларацию 3-НДФЛ. Также ее необходимо
            составлять и при декларировании дохода, например, от продажи
            имущества.
          </p>
          <p>
            Однако не стоит отказываться от возможности вернуть 13% от суммы
            своих расходов Декларацию можно попробовать составить
            самостоятельно, но ФНС откажет в выплате в случае ошибки в расчетах.
            А в случае нарушения сроков подачи декларации о доходах вы будете
            обязаны оплатить штраф или пени, поэтому подготовку декларации лучше
            доверить профессионалам.
          </p>
          <p>
            От качества заполнения налоговой декларации 3-НДФЛ зависит на
            сколько быстро государство вернет Вам Ваши деньги. Камеральная
            проверка проходит в срок до 3-х месяцев (есть внутреннее
            распоряжение, по которому срок проверки сокращен до 2 недель, если
            все корректно оформлено). При положительном решении в течение 1
            месяца Вам переводят денежные средства на указанный в заявлении счет
            в банке.
          </p>
          <p>
            Если в ходе проверки декларации 3-НДФЛ налоговой инспекцией будут
            выявлены ошибки или несоответствие сведений указанных в декларации
            сведениям, имеющимся в приложенных к ней документах, то срок
            получения вычета увеличится. Необходимо будет исправить ошибки подав
            уточняющую декларацию, по которой начинается новая проверка.
          </p>
          <p>
            После консультации с нашим специалистом мы подготовим и отправим
            декларацию вам на электронную почту двумя разными файлами: xml и
            pdf. Первый файл адаптирован для загрузки в личном кабинете
            налогоплательщика самостоятельно, второй файл можно распечатать и
            сдать лично в ФНС в бумажном виде. Пошаговую инструкцию для
            самостоятельной загрузки декларации через ЛК налогоплательщика
            предоставим.
          </p>
        </article>
      </div>
    </>
  );
};
