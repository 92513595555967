import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import './Question.scss';

export const Question = ({ popup }: { popup: string }) => {
  const [isShown, setShown] = useState(false);
  const [width, setWidth] = useState(200); // ширина вспл окна
  const [documentWidth, setDocumentWidth] = useState(0); // ширина документа
  const [offset, setOffset] = useState(0);
  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  } as Record<string, CSSProperties>;

  useEffect(() => {
    setDocumentWidth(document.documentElement.clientWidth);
  }, []);

  const getWidth = useCallback((node: HTMLElement) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const getPosition = useCallback(
    (node: HTMLElement) => {
      if (node !== null) {
        const size = {
          x: node.getBoundingClientRect().x, // левый край вспл окна
          right: node.getBoundingClientRect().right, // правый край вспл окна
        };

        if (size.right > documentWidth) {
          setOffset(size.x - (documentWidth - width - 15));
        } else {
          if (documentWidth - size.x > width) {
            setOffset(0);
          } else {
            setOffset(size.x - (documentWidth - width - 15));
          }
        }
      }
    },
    [width, documentWidth],
  );

  return (
    <div
      className="question"
      onMouseOver={() => setShown(true)}
      onMouseOut={() => setShown(false)}
      ref={getPosition}
    >
      <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="26" height="26" rx="13" fill="white" />
        <path
          d="M11.5646 15.4971V14.8131C11.5646 14.1925 11.6976 13.6668 11.9636 13.2361C12.2296 12.7928 12.6856 12.3368 13.3316 11.8681C13.9016 11.4628 14.307 11.1145 14.5476 10.8231C14.801 10.5191 14.9276 10.1645 14.9276 9.75914C14.9276 9.36647 14.7756 9.06247 14.4716 8.84714C14.1803 8.63181 13.7686 8.52414 13.2366 8.52414C12.7046 8.52414 12.179 8.60647 11.6596 8.77114C11.1403 8.93581 10.6083 9.15747 10.0636 9.43614L9.05664 7.40314C9.67731 7.06114 10.3486 6.78247 11.0706 6.56714C11.7926 6.35181 12.5843 6.24414 13.4456 6.24414C14.763 6.24414 15.7826 6.56081 16.5046 7.19414C17.2393 7.82747 17.6066 8.63181 17.6066 9.60714C17.6066 10.1265 17.5243 10.5761 17.3596 10.9561C17.195 11.3361 16.948 11.6908 16.6186 12.0201C16.2893 12.3495 15.8776 12.6915 15.3836 13.0461C15.0036 13.3248 14.7123 13.5591 14.5096 13.7491C14.3196 13.9265 14.1866 14.1101 14.1106 14.3001C14.0473 14.4775 14.0156 14.6928 14.0156 14.9461V15.4971H11.5646ZM11.2606 18.6701C11.2606 18.0875 11.419 17.6821 11.7356 17.4541C12.0523 17.2135 12.4386 17.0931 12.8946 17.0931C13.338 17.0931 13.718 17.2135 14.0346 17.4541C14.3513 17.6821 14.5096 18.0875 14.5096 18.6701C14.5096 19.2275 14.3513 19.6328 14.0346 19.8861C13.718 20.1268 13.338 20.2471 12.8946 20.2471C12.4386 20.2471 12.0523 20.1268 11.7356 19.8861C11.419 19.6328 11.2606 19.2275 11.2606 18.6701Z"
          fill="#347CC4"
        />
      </svg>
      <Transition in={isShown} timeout={duration} unmountOnExit mountOnEnter>
        {/* @ts-ignore */}
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              left: `${-offset}px`,
            }}
            className="question__clue"
            dangerouslySetInnerHTML={{ __html: popup }}
            ref={getWidth}
          />
        )}
      </Transition>
    </div>
  );
};
