import React from 'react';
import { Link } from 'react-router-dom';
import { useRouteData } from '../../../hooks/useRouteData';
import './NotFound.scss';

export const NotFound = () => {
  const { Crumbs, title } = useRouteData();

  return (
    <div className="container">
      <Crumbs />
      <h2>{title}</h2>
      <div className="error-404">
        <svg
          version="1.1"
          viewBox="0 0 96 96"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="m48.242 11.113c1.5051 2.09e-4 2.9079 0.8334 3.688 2.2464l35.227 63.814c1.712 3.1035-0.37572 6.8382-3.69 6.8382h-70.448c-3.3143 4.9e-5 -5.402-3.7346-3.69-6.8382l35.225-63.814c0.78013-1.413 2.1829-2.2462 3.688-2.2464z"
              color="#000000"
              fill="#ffcf71"
            />
            <path
              d="m65.564 38.059c-0.26381 0.5491-0.51299 1.1098-0.7832 1.6543-0.40655 0.81923-0.81869 1.6328-1.2402 2.4414-0.42155 0.80862-0.85192 1.6124-1.2891 2.4102s-0.88259 1.5903-1.3359 2.377c-0.45335 0.78667-0.91461 1.5665-1.3848 2.3418s-0.94798 1.5448-1.4355 2.3086c-0.48757 0.76375-0.98465 1.5215-1.4902 2.2734-0.50558 0.75192-1.0207 1.4984-1.5449 2.2383-0.5242 0.73986-1.0581 1.4736-1.6016 2.2012-0.54343 0.72755-1.0949 1.4491-1.6582 2.1641-0.56326 0.71499-1.137 1.4228-1.7207 2.125s-1.1785 1.3987-1.7832 2.0879c-0.60473 0.68916-1.2193 1.371-1.8457 2.0469-0.62638 0.67588-1.2635 1.3455-1.9121 2.0078-0.64862 0.66236-1.309 1.3182-1.9805 1.9668-0.67148 0.64859-1.3539 1.2912-2.0488 1.9258-0.69493 0.63458-1.4021 1.2605-2.1211 1.8809-0.71899 0.62033-1.4517 1.234-2.1953 1.8398-0.74366 0.60583-1.4986 1.2038-2.2676 1.7949-0.76893 0.5911-1.5509 1.1758-2.3457 1.752-0.7948 0.57612-1.6025 1.1442-2.4238 1.7051-0.82128 0.56089-1.6555 1.1147-2.5039 1.6602-0.395 0.25395-0.80411 0.49944-1.2051 0.75h58.02c3.3143 4.9e-5 5.4015-3.7344 3.6895-6.8379l-21.592-39.115z"
              color="#000000"
              fill="#f3b43a"
            />
            <path
              d="m48.242 8.5625c-2.6024 3.617e-4 -4.9952 1.4595-6.2695 3.7676l-34.789 63.029a1.5002 1.5002 0 0 0-0.00195 0c-2.7126 4.9173 0.73375 11.203 6.2715 11.203h69.58c5.5377 8.1e-5 8.9841-6.2858 6.2715-11.203l-34.793-63.029c-1.2743-2.3081-3.6672-3.7672-6.2695-3.7676zm0 3c1.4866 2.07e-4 2.8721 0.82314 3.6426 2.2188l34.793 63.027c1.691 3.0653-0.37109 6.754-3.6445 6.7539h-69.58c-3.2734 4.8e-5 -5.3355-3.6886-3.6445-6.7539l34.791-63.027c0.77052-1.3956 2.156-2.2185 3.6426-2.2188z"
              color="#000000"
              fill="#6d6d6d"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <g transform="translate(.033804)" fill="#6d6d6d">
              <path
                d="m46.711 30.084v13.58h3v-13.58zm0 16.074v3h3v-3z"
                color="#000000"
              />
              <path
                d="m32.369 56.182-2.375 11.223-2e-3 0.01172c-0.0105 0.05353-0.23911 1.4162 0.64062 2.2988 0.40651 0.40784 0.91319 0.62653 1.3027 0.70508 0.38955 0.07855 0.82713 0.04883 0.64258 0.04883h2.3926v2.7129h3v-2.7129h1.5664v-3h-1.5664v-4.0645h-3v4.0645h-1.9238l2.2578-10.666z"
                color="#000000"
              />
              <path
                d="m59.293 56.182-2.375 11.223-2e-3 0.01172c-0.0105 0.05353-0.24106 1.4162 0.63867 2.2988 0.40651 0.40784 0.91319 0.62653 1.3027 0.70508 0.38955 0.07855 0.82713 0.04883 0.64258 0.04883h2.3926v2.7129h3v-2.7129h1.5684v-3h-1.5684v-4.0645h-3v4.0645h-1.9219l2.2578-10.666z"
                color="#000000"
              />
              <path
                d="m47.893 56.232c-3.0568 0-5.5645 2.5077-5.5645 5.5645v5.8711c0 3.0567 2.5076 5.5645 5.5645 5.5645s5.5664-2.5077 5.5664-5.5645v-5.8711c0-3.0567-2.5096-5.5645-5.5664-5.5645zm0 3c1.447 0 2.5664 1.1181 2.5664 2.5645v5.8711c0 1.4464-1.1194 2.5645-2.5664 2.5645-1.447 0-2.5645-1.1181-2.5645-2.5645v-5.8711c0-1.4464 1.1175-2.5645 2.5645-2.5645z"
                color="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </svg>
        <div>
          <p>
            Такой страницы не существует :(<br></br>Вернуться на{' '}
            <Link to={'/'}>главную страницу</Link>.
          </p>
        </div>
      </div>
    </div>
  );
};
