import React, { FC, HTMLAttributes } from 'react';
import { HeaderWithMobileMenu } from '../../shared/HeaderWithMobileMenu/HeaderWithMobileMenu';
import { Footer } from './Footer/Footer';
import { CallbackFormsSet } from '../../shared/Callback/CallbackFormsSet';
import { BackToTopButton } from '../../shared/BackToTopButton/BackToTopButton';
import { Outlet } from 'react-router-dom';
import './PublicLayout.scss';

interface PublicLayoutProps extends HTMLAttributes<HTMLDivElement> {}

export const PublicLayout: FC<PublicLayoutProps> = () => {

  return (
    <div className="page">
      <HeaderWithMobileMenu mix={'blue'} />
      <CallbackFormsSet />
      <main className="main">
        <Outlet />
      </main>
      <Footer />
      <BackToTopButton />
    </div>
  );
};
