/**
 * вернёт дату в формате "3 августа 2023 г."
 */
export const formatDate = (date: string) => {

  if(typeof date !== 'string') return;

  const d = new Date(date);

  if (isNaN(d.getTime())) {
    return 'Неверная дата';
  }

  return new Intl.DateTimeFormat('ru-RU', {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(d)
};