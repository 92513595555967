import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { ModalWrapper } from '../../ModalWrapper/ModalWrapper';
import { useCallbackForm } from '../CallbackFormContext';
import { Button } from '../../Button/Button';
import './ThankYouForm.scss';

/**
 * Отрисует окно "Ваша заявка принята"
 */
export const ThankYouForm = () => {
  const { isThankYouForm, setThankYouFormClose } = useCallbackForm();

  return (
    <CSSTransition
      in={isThankYouForm}
      timeout={300}
      mountOnEnter
      unmountOnExit
      classNames="modal"
    >
      <ModalWrapper onClose={setThankYouFormClose}>
        <div className="thank-you-form">
          <svg
            width="103"
            height="103"
            viewBox="0 0 103 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M51.5 101.5C79.1142 101.5 101.5 79.1142 101.5 51.5C101.5 23.8858 79.1142 1.5 51.5 1.5C23.8858 1.5 1.5 23.8858 1.5 51.5C1.5 79.1142 23.8858 101.5 51.5 101.5ZM51.5 103C79.9427 103 103 79.9427 103 51.5C103 23.0573 79.9427 0 51.5 0C23.0573 0 0 23.0573 0 51.5C0 79.9427 23.0573 103 51.5 103Z"
              fill="#FFCF71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M51.5 96.5C76.3528 96.5 96.5 76.3528 96.5 51.5C96.5 26.6472 76.3528 6.5 51.5 6.5C26.6472 6.5 6.5 26.6472 6.5 51.5C6.5 76.3528 26.6472 96.5 51.5 96.5ZM51.5 98C77.1812 98 98 77.1812 98 51.5C98 25.8188 77.1812 5 51.5 5C25.8188 5 5 25.8188 5 51.5C5 77.1812 25.8188 98 51.5 98Z"
              fill="#FFCF71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M68.589 38.4644L47.5878 65.0999L34.4922 53.0519L35.5078 51.9481L47.4122 62.9001L67.411 37.5356L68.589 38.4644Z"
              fill="#FFCF71"
            />
          </svg>
          <div className="thank-you-form__text">
            <h3>Ваша заявка принята</h3>
            <p>Наши специалисты перезвонят вам в течение нескольких минут</p>
          </div>
          <Button theme="beige" onClick={setThankYouFormClose}>
            Ок
          </Button>
        </div>
      </ModalWrapper>
    </CSSTransition>
  );
};
