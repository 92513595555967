import React, { FC, HTMLAttributes, useState } from 'react';
import './HeaderWithMobileMenu.scss';
import { Header } from '../../public/PublicLayout/Header/Header';
import { MobileMenu } from '../MobileMenu/MobileMenu';

interface HeaderWithMobileMenuProps extends HTMLAttributes<HTMLDivElement> {
  mix?: 'blue';
}

/**`
 * Хедер с мобильным меню
 */
export const HeaderWithMobileMenu: FC<HeaderWithMobileMenuProps> = ({
  mix,
}) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <Header
        setMobileMenuClose={() => setMobileMenuOpen(false)}
        setMobileMenuOpen={() => setMobileMenuOpen(true)}
        isMobileMenuOpen={isMobileMenuOpen}
        mix={mix}
      />
      <MobileMenu
        isMobileMenuOpen={isMobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      />
    </div>
  );
};
