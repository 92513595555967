import React, { CSSProperties, FC, HTMLAttributes } from 'react';
import stub from '../../../../assets/images/Обложка.png';

interface ImageStubProps extends HTMLAttributes<HTMLImageElement> {
  style?: CSSProperties;
}

export const ImageStub: FC<ImageStubProps> = ({ style }) => {

  return <img 
    src={stub} 
    alt={'Заглушка изображения'} 
    style={{
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      ...style
    }}
  />
}
