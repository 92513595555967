import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ScrollToTop } from '../shared/ScrollToTop/ScrollToTop';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../public/ErrorPage/ErrorPage';
import { Public } from './Public';

export const App = () => {

  return (
    <ErrorBoundary FallbackComponent={ErrorPage} onReset={() => location.href = '/'}>
      <Router basename='/'>
        <ScrollToTop />
        <Public />
      </Router>
    </ErrorBoundary>
  );
};
