import React, {
  FC,
  createContext,
  useState,
  HTMLAttributes,
  useContext,
} from 'react';

type TCallbackFormContext = {
  isCallbackForm: boolean;
  setCallbackFormOpen: () => void;
  setCallbackFormClose: () => void;
  formFieldsData: Record<'name' | 'phone' | 'message', string>;
  updateFormFieldsData: (
    a: Record<'name' | 'phone' | 'message', string>,
  ) => void;
  isLoading: boolean;
  isThankYouForm: boolean;
  setThankYouFormClose: () => void;
  setThankYouFormOpen: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
};

export const CallbackFormContext = createContext({
  isCallbackForm: false,
} as TCallbackFormContext);

export const useCallbackForm = () => useContext(CallbackFormContext);

interface TCallbackFormContextProvider extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

/**
 * Контекст формы обратного звонка
 */
export const CallbackFormContextProvider: FC<TCallbackFormContextProvider> = ({
  children,
}) => {
  const [isCallbackForm, setCallbackForm] = useState(false);
  const [formFieldsData, setFormFieldsData] = useState({
    name: '',
    phone: '',
    message: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [isThankYouForm, setThankYouForm] = useState(false);

  const updateFormFieldsData = (
    a: Record<'name' | 'phone' | 'message', string>,
  ) => {
    if (
      a.name !== formFieldsData.name ||
      a.phone !== formFieldsData.phone ||
      a.message !== formFieldsData.message
    ) {
      setFormFieldsData(a);
    }
  };

  return (
    <CallbackFormContext.Provider
      value={{
        isCallbackForm,
        setCallbackFormOpen: () => setCallbackForm(true),
        setCallbackFormClose: () => setCallbackForm(false),
        formFieldsData,
        updateFormFieldsData,
        isLoading,
        setLoadingTrue: () => setLoading(true),
        setLoadingFalse: () => setLoading(false),
        isThankYouForm,
        setThankYouFormClose: () => setThankYouForm(false),
        setThankYouFormOpen: () => setThankYouForm(true),
      }}
    >
      {children}
    </CallbackFormContext.Provider>
  );
};
