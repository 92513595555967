import { useQuery } from 'react-query';
import { getMoreAboutTariffs } from '../restAPI';
import { TMoreAboutTariffs, TRow } from '../types';

const staleTime = 60 * 60 * 1000;

const processData = (data: TMoreAboutTariffs[]): Promise<TRow[]> =>
  new Promise((resolve, reject) => {
    try {
      const dataToArr: TRow[] = [];

      data.map(({ title, tariffs, additionalService, unit, id }) => {
        const tariffsData = tariffs
          .sort((a, b) => (a.tariffPlanId < b.tariffPlanId ? 1 : -1))
          .map((tariff) => tariff.contained);
        dataToArr.push({
          id,
          row: [title, ...tariffsData, additionalService, unit],
        });
      });

      resolve(dataToArr);
    } catch (e) {
      reject(e);
    }
  });

export const useProcessedMoreAboutTariffs = () => {
  const { data: initialData } = useQuery({
    queryKey: ['more-about-tariffs'],
    queryFn: getMoreAboutTariffs,
    placeholderData: [],
    staleTime,
  });

  const { data: processedData } = useQuery({
    queryKey: ['processed-more-about-tariffs'],
    queryFn: () => processData(initialData),
    placeholderData: [],
    staleTime,
    enabled: !!initialData.length,
  });

  return {
    isMoreAboutTariffsLoading: !processedData.length,
    moreAboutTariffs: processedData,
  };
};
