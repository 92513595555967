import { useQuery } from 'react-query';
import { getZeroReporting } from '../restAPI';
import { TZeroReporting, TRow } from '../types';

const staleTime = 60 * 60 * 1000;

const processData = (data: TZeroReporting[]): Promise<TRow[]> =>
  new Promise((resolve, reject) => {
    try {
      const dataToArr: TRow[] = [];

      data.map(({ title, USN, OSNO, id }) => {
        dataToArr.push({
          id,
          row: [title, `${USN} ₽`, `${OSNO} ${OSNO !== '-' ? '₽' : ''}`],
        });
      });

      resolve(dataToArr);
    } catch (e) {
      reject(e);
    }
  });

export const useProcessedZeroReporting = () => {
  const { data: initialData } = useQuery({
    queryKey: ['zero-reporting'],
    queryFn: getZeroReporting,
    placeholderData: [],
    staleTime,
  });

  const { data: processedData } = useQuery({
    queryKey: ['processed-zero-reporting'],
    queryFn: () => processData(initialData),
    placeholderData: [],
    staleTime,
    enabled: !!initialData.length,
  });

  return {
    isZeroReportingLoading: !processedData.length,
    zeroReporting: processedData,
  };
};
