import React, { PropsWithChildren, useEffect, useState } from 'react';
import './Checkbox.scss';

// NOTE: взял отсюда https://frontips.ru/css-stili-dlya-checkbox/

type CheckboxProps = {
  onCheck?: () => void;
  onUncheck?: () => void;
} & PropsWithChildren;

export const Checkbox = ({ children, onCheck, onUncheck }: CheckboxProps) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      onCheck && onCheck();
    } else {
      onUncheck && onUncheck();
    }
  }, [checked]);

  return (
    <label className="checkbox">
      <input
        type="checkbox"
        onChange={() => setChecked(!checked)}
        checked={checked}
      />
      <div className="checkbox__mark">
        {checked && (
          <svg
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="24" height="24" fill="white" />
            <rect
              x="1"
              y="1"
              width="24"
              height="24"
              stroke="#0659AB"
              strokeWidth="2"
            />
            <path
              d="M7 13L12.2963 18L19 7.5"
              stroke="#0659AB"
              strokeWidth="3"
            />
          </svg>
        )}
      </div>
      <div className="checkbox__body">{children}</div>
    </label>
  );
};
