type Goal =
  | 'openCallbackForm'
  | 'sendCallbackForm'
  | 'dailPhone'
  | 'goToTelegram'
  | 'goToWhatsApp'
  | 'goToViber'
  | 'openEmail'
  | 'entrustService';

const counterId = 97390539;

export function useYandexMetrika() {
  const reachGoal = (goal: Goal) => ym(counterId, 'reachGoal', goal);

  return { reachGoal };
}
