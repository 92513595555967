// название коллекции в firebase
export const ACTIVITY_FEATURES = 'activity-features';
export const ADDITIONAL_SERVICES = 'additional-services';
export const EMPLOYEES_SETTINGS = 'employees-settings';
export const LEGAL_FORM = 'legal-form';
export const LEGAL_SERVICES = 'legal-services';
export const MORE_ABOUT_TARIFFS = 'more-about-tariffs';
export const NUMBER_OF_OPERATIONS = 'number-of-operations';
export const SERVICES_FOR_INDIVIDUALS = 'services-for-individuals';
export const TARIFF = 'tariff';
export const TARIFF_PLAN = 'tariff-plan';
export const TAXATION_SYSTEM = 'taxation-system';
export const ZERO_REPORTING = 'zero-reporting';
export const IMAGES = 'images';
export const NEWS = 'news';

// Базовый url
export const URL = `https://firestore.googleapis.com/v1/projects/buhetalon-3/databases/(default)/documents/`;
