import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';
import './CloseButton.scss';

interface CloseButtonProps extends HTMLAttributes<HTMLButtonElement> {
  theme: 'transparent';
  mixin?: 'absolute-position';
  onClose?: () => void;
}

export const CloseButton: FC<CloseButtonProps> = ({
  theme,
  mixin,
  onClose,
  ...restProps
}) => {
  const themeClass = `${theme}-theme`;

  return (
    <button
      {...restProps}
      className={classNames('close-button', themeClass, mixin)}
      onClick={onClose}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="times"
          d="M7 7L15 15M23 23L15 15M15 15L23 7M15 15L7 23"
          strokeWidth="2"
        />
      </svg>
    </button>
  );
};
