import React, {
  FC,
  HTMLAttributes,
  useState,
  useRef,
  CSSProperties,
  SyntheticEvent,
  ReactNode,
} from 'react';
import { NavLink } from 'react-router-dom';
import { withArrowDown } from '../../../hocs/withArrowDown';
import { CSSTransition } from 'react-transition-group';
import { Dropdown } from '../Dropdown/Dropdown';

interface MenuItemWithDropdown extends HTMLAttributes<HTMLUListElement> {
  style?: CSSProperties | undefined;
  children: ReactNode;
}

const ElementWithArrowDown = withArrowDown(({ children }) => <>{children}</>);

export const MenuItemWithDropdown: FC<MenuItemWithDropdown> = ({
  children,
  style,
  ...restProps
}) => {
  const targetRef = useRef<HTMLAnchorElement>(null);
  const [dropdownShown, setDropdownShown] = useState(false);
  const [animate, setAnimate] = useState(false);

  const ajustedWidth = () => {
    if (style?.hasOwnProperty('width')) {
      return { ...style, width: `calc(${style.width} + 30px)` };
    }

    return style;
  };

  // Если нужно, чтобы дропдаун открывался по клику
  // const dropdownClickHandler = (event: SyntheticEvent<HTMLAnchorElement>) => {
  //     event.stopPropagation();
  //     event.preventDefault();

  //     if (!dropdownShown) {
  //       setDropdownShown(true);
  //       setAnimate(true);
  //     } else {
  //       setAnimate(false);
  //       setTimeout(() => setDropdownShown(false), 200);
  //     }
  // };

  const dropdownOpenHandler = (event: SyntheticEvent<HTMLAnchorElement>) => {
    // Если нужно, чтобы дропдаун открывался по клику
    // event.stopPropagation();
    // event.preventDefault();
    setDropdownShown(true);
    setAnimate(true);
  };

  const dropdownCloseHandler = (event: SyntheticEvent<HTMLAnchorElement>) => {
    // Если нужно, чтобы дропдаун открывался по клику
    // event.stopPropagation();
    // event.preventDefault();

    setAnimate(false);
    setTimeout(() => setDropdownShown(false), 500);
  };

  return (
    <NavLink
      to="/service"
      ref={targetRef}
      // Если нужно, чтобы дропдаун открывался по клику
      // onClick={(e) => dropdownClickHandler(e)}
      onMouseEnter={(e) => dropdownOpenHandler(e)}
      onMouseLeave={(e) => dropdownCloseHandler(e)}
    >
      <ElementWithArrowDown isRotated={dropdownShown}>
        Услуги
      </ElementWithArrowDown>
      <CSSTransition
        in={animate}
        timeout={300}
        classNames={'dropdown'}
        mountOnEnter
        unmountOnExit
        appear
      >
        {/* @ts-ignore */}
        <Dropdown
          {...restProps}
          targetRef={targetRef}
          shown={dropdownShown}
          onShownChange={setDropdownShown}
          setAnimate={setAnimate}
          style={ajustedWidth()}
        >
          {children}
        </Dropdown>
      </CSSTransition>
    </NavLink>
  );
};
