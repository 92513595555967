import React from 'react';
import { useRouteData } from '../../../hooks/useRouteData';
import { useProcessedCalculatorData } from '../../../core/query-hooks/useProcessedCalculatorData';
import { CalculatorBody } from './CalculatorBody';
import './Calculator.scss';

export const Calculator = () => {
  const { Crumbs, title } = useRouteData();
  const { isCalculatorDataLoadings, calculatorData } =
    useProcessedCalculatorData();

  if (isCalculatorDataLoadings) {
    return (
      <>
        <div className="container">
          <Crumbs />
          <h2>{title}</h2>
        </div>

        <div className="container">Загрузка...</div>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <Crumbs />
        <h2>{title}</h2>
      </div>

      {calculatorData && <CalculatorBody dataSet={calculatorData} />}
    </>
  );
};
