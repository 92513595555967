import { useQuery } from 'react-query';
import {
  getTariffs,
  getLegalForm,
  getTariffPlan,
  getNumberOfOperations,
} from '../restAPI';
import {
  TTariff,
  TLegalForm,
  TTariffPlan,
  TNumberOfOperations,
  TTariffTable,
} from '../types';

type TData = {
  tariffsData: { tariff: TTariff[] };
  legalFormData: TLegalForm[];
  tariffPlanData: TTariffPlan[];
  numberOfOperationsData: TNumberOfOperations[];
};

const staleTime = 60 * 60 * 1000;

const processData = ({
  tariffsData,
  legalFormData,
  tariffPlanData,
  numberOfOperationsData,
}: TData): Promise<TTariffTable[]> =>
  new Promise((resolve, reject) => {
    try {
      const max = Math.max(
        ...numberOfOperationsData.map((operation) => operation.max),
      );

      const data: unknown[] = [];

      legalFormData.forEach((legalForm) => {
        if (legalForm.isShown) {
          data.push({
            legalFormTitle: legalForm.title,
            data: tariffPlanData.map((tariffPlan) => {
              const obj1 = tariffsData.tariff.find(
                (item) =>
                  item.legalFormId === legalForm.id &&
                  item.tariffPlanId === tariffPlan.id,
              );

              if (obj1) {
                return {
                  tariffPlanTitle: tariffPlan.title,
                  maxNumberOfOperations: max,
                  data: numberOfOperationsData.map((numberOfOperations) => {
                    const obj2 = obj1.data.find(
                      (item1) =>
                        item1.numberOfOperationsId === numberOfOperations.id,
                    );

                    if (obj2) {
                      return {
                        id: obj2.numberOfOperationsId,
                        row: [
                          'Количество операций',
                          'Общая система (ОСНО)',
                          'УСН (Доходы - Расходы)',
                          'УСН (Доходы 6%)',
                        ].map((item3) => {
                          if (item3 === 'Количество операций')
                            return numberOfOperations.title;

                          const obj3 = obj2.data_1.find(
                            (item4) => item4.title === item3,
                          );

                          if (obj3) return obj3.value.toString();
                        }),
                      };
                    }
                  }),
                };
              }
            }),
          });
        }
      });

      resolve(data as TTariffTable[]);
    } catch (e) {
      reject(e);
    }
  });

export const useProcessedTariffs = () => {
  const { data: tariffsData } = useQuery({
    queryKey: ['tariffs'],
    queryFn: getTariffs,
    staleTime,
  });

  const { data: legalFormData } = useQuery({
    queryKey: ['legal-form'],
    queryFn: getLegalForm,
    staleTime,
  });

  const { data: tariffPlanData } = useQuery({
    queryKey: ['tariff-plan'],
    queryFn: getTariffPlan,
    staleTime,
  });

  const { data: numberOfOperationsData } = useQuery({
    queryKey: ['number-of-operations'],
    queryFn: getNumberOfOperations,
    staleTime,
  });

  const { data: processedData } = useQuery({
    queryKey: ['processed-tariffs'],
    queryFn: () =>
      processData({
        tariffsData,
        legalFormData,
        tariffPlanData,
        numberOfOperationsData,
      }),
    placeholderData: [],
    staleTime,
    enabled:
      !!tariffsData &&
      !!legalFormData &&
      !!tariffPlanData &&
      !!numberOfOperationsData,
  });

  return {
    tariffs: processedData,
    isTariffsLoadings: !processedData.length,
  };
};
