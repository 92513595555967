import React, { Fragment, useState } from 'react';
import { PriceTableComponents } from './PriceTableComponents';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import AnimateHeight from 'react-animate-height';

type TTariffTable = {
  legalFormTitle: string;
  data: {
    tariffPlanTitle: string;
    maxNumberOfOperations: number;
    data: { id: string; row: string[] }[];
  }[];
};

type TariffsTableProps = {
  isLoading: boolean;
  data: TTariffTable[];
  headers: string[];
  title: string;
  pretext?: string[];
  footnote?: () => React.JSX.Element;
};

export const TariffsTable = ({
  isLoading,
  data,
  headers,
  title,
  pretext,
  footnote,
}: TariffsTableProps) => {
  const isDesktop = useMediaQuery('(min-width: 600px)');
  const [isOpen, setOpen] = useState(false);

  return (
    <PriceTableComponents>
      <PriceTableComponents.Title>{title}</PriceTableComponents.Title>
      {isDesktop ? (
        <>
          <PriceTableComponents.Pretext pretext={pretext} />
          {data.map((item) => (
            <Fragment key={item.legalFormTitle}>
              <PriceTableComponents.Subtitle subtitle={item.legalFormTitle} />
              <div className="table">
                <PriceTableComponents.THead headers={headers} />
                {item.data.map((item1) => (
                  <Fragment key={item1.tariffPlanTitle + item.legalFormTitle}>
                    <PriceTableComponents.TableTitle
                      tableTitle={item1.tariffPlanTitle}
                    />
                    <PriceTableComponents.TBody
                      data={item1.data}
                      isLoading={isLoading}
                      maxNumberOfOperations={item1.maxNumberOfOperations}
                    />
                  </Fragment>
                ))}
              </div>
            </Fragment>
          ))}
          <PriceTableComponents.Footnote footnote={footnote} />
        </>
      ) : (
        <>
          <PriceTableComponents.Pretext pretext={pretext} />
          {data.map((item) => (
            <>
              <PriceTableComponents.Subtitle subtitle={item.legalFormTitle} />
              <AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
                <div className="table">
                  {item.data.map((item1) => (
                    <>
                      <PriceTableComponents.TableTitle
                        tableTitle={item1.tariffPlanTitle}
                      />
                      <PriceTableComponents.TBodyMobile
                        data={item1.data}
                        isLoading={isLoading}
                        maxNumberOfOperations={item1.maxNumberOfOperations}
                        headers={headers}
                      />
                    </>
                  ))}
                </div>
              </AnimateHeight>
              <PriceTableComponents.Button
                isOpen={isOpen}
                onClick={() => setOpen((prev) => !prev)}
              />
            </>
          ))}

          <PriceTableComponents.Footnote footnote={footnote} />
        </>
      )}
    </PriceTableComponents>
  );
};
