import React, { useState, useRef, useEffect } from 'react';
import { SquareButton } from '../../../shared/SquareButton/SquareButton';
import { getImage } from './images';
import './Carousel.scss';
import { useSwipeable } from 'react-swipeable';

interface ImageProps {
  image: string;
  mix?: string;
}

const Image = ({ image }: ImageProps) => {
  const img = getImage(image);

  if (!img) {
    return null;
  }

  return (
    <div className="image">
      <img className="image-initial" src={img.default} />
      <img className="image-overlay" src={img.hovered} />
    </div>
  );
};

/**
 * Карусель на главной странице
 */
export const Carousel = () => {
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const imagesBlock = useRef(null);
  const containerBlock = useRef(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const getCarouselWidth = setTimeout(() => {
      setCarouselWidth(imagesBlock.current.getBoundingClientRect().width),
      setContainerWidth(containerBlock.current.getBoundingClientRect().width);
    }, 10);

    return () => clearTimeout(getCarouselWidth);
  }, []);

  useEffect(() => {
    if (!imagesBlock.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      if(imagesBlock.current.getBoundingClientRect().width !== carouselWidth) {
        setCarouselWidth(imagesBlock.current.getBoundingClientRect().width);
      }
    });

    resizeObserver.observe(imagesBlock.current);

    return () => {
      resizeObserver.disconnect();
    }
  }, [imagesBlock.current])

  const leftArrowClickHandler = () => {
    if (offset < 0) {
      setOffset((prev) => prev + 100);
    }
  };

  const rightArrowClickHandler = () => {
    if (carouselWidth + offset > containerWidth) {
      setOffset((prev) => prev - 100);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => rightArrowClickHandler(),
    onSwipedRight: () => leftArrowClickHandler(),
  });

  return (
    <div className="carousel" {...handlers}>
      <SquareButton
        theme="bordered"
        arrow="left"
        onclick={leftArrowClickHandler}
        mixin="arrow-button"
      />
      <div className="carousel__container" ref={containerBlock}>
        <div
          className="carousel__images"
          ref={imagesBlock}
          style={{ transform: `translateX(${offset}px)` }}
        >
          {[
            'tek',
            'tek-1',
            'galaxy',
            'sulak',
            'roma',
            'pushkin-tau',
            'help-quick',
          ].map((item) => (
            <Image key={item} image={item} />
          ))}
        </div>
      </div>
      <SquareButton
        theme="bordered"
        arrow="right"
        onclick={rightArrowClickHandler}
        mixin="arrow-button"
      />
    </div>
  );
};
