import React, { useEffect } from 'react';
import { useRouteData } from '../../../hooks/useRouteData';
import { useNewsItem } from '../../../core/query-hooks/useNewsItem';
import { NewsImage } from '../../shared/Image/NewsImage';
import { formatDate } from '../../../utils/formatDate';
import { useParams } from 'react-router-dom';
import './NewsItem.scss';

/**
 * Компонент отображает страницу новости
 */
export const NewsItem = () => {
  const {id} = useParams();
  const { isNewsLoading, newsItem } = useNewsItem(id);
  const { Crumbs, title, updateRoutes } = useRouteData();

  useEffect(() => {
    if(newsItem?.title) {
      updateRoutes({
        paramsId: id, 
        title: newsItem.title, 
        description: "Компания БухЭталонЪ. Полный спектр бухгалтерских услуг для ООО и ИП. Раздел новостей: " + newsItem.title + "."
      });
    }
  }, [newsItem?.title])

  if(!id) return null;

  if(isNewsLoading) {
    return <div className='container'>Загрузка...</div>;
  }

  return (
    <div className='container'>
      <Crumbs />
      <h2>{title}</h2>
      
      <article className='article'>
        {newsItem?.date && <div className='date'>{formatDate(newsItem?.date)}</div>}
        {newsItem?.image && <div className='announce-img'><NewsImage src={newsItem?.image?.src} alt={newsItem?.image?.alt} /></div>}
        {newsItem?.announce && <div className='announce'>{newsItem?.announce}</div>}
        {newsItem?.editorValue && <div dangerouslySetInnerHTML={{__html: newsItem?.editorValue}}></div>}
      </article>
    </div>
    );
};
