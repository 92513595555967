import React, { CSSProperties } from 'react';

export const YesSign = () => {
  const style: CSSProperties = {
    display: 'block',
  };

  return (
    <svg
      style={style}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#39AC7C" />
      <path
        d="M5.73926 10.9999L10.0436 14.3477L15.7827 6.69556"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
